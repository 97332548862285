exports.ChangeLinkStructure = (link) => {
    let link_name;

  if (link === "/") {
    link_name = "Dashboard";
  } else {
    let lowercase_link_name = link.substring(1, link.length);
    link_name =
      lowercase_link_name.charAt(0).toUpperCase() +
      lowercase_link_name.substring(1, lowercase_link_name.length);
  }

  return {link_name}
}