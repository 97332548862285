import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App/App';

import { BrowserRouter } from 'react-router-dom'


// bootstarp
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

import './Styles/fontawesome/css/font-awesome.min.css';
import './Styles/index.css';
import './Styles/themify-icons/themify-icons.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <BrowserRouter><App /></BrowserRouter>
);
