import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";

const ViewSliderMessages = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [sliderFormData, setSliderFormData] = useState();

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(
          `https://backend.seo-calgary.ca/api/v1/frontendForms/getSliderFormDataById/${id}`
        )
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            setSliderFormData(res.data.data);
          }
        });
    };
    fetchData();
  }, []);

  const deleteMessage = (id) => {
    if (window.confirm("Delete the item?")) {
      axios
        .get(
          `https://backend.seo-calgary.ca/api/v1/frontendForms/deleteSliderFormData/${id}`,
          {
            headers: {
              accessToken: localStorage.getItem("authtoken"),
            },
          }
        )
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            navigate(`/slider-messages`);
          }
        });
    }
  };

  return (
    <>
      {sliderFormData && (
        <div>
          <Link
            to="/slider-messages"
            class="btn btn-danger btn-sm mr-2"
            title="Back"
          >
            Back
          </Link>
          <h1>Message:</h1>
          <p>Name : {sliderFormData.name}</p>
          <p>Email : {sliderFormData.email}</p>
          <p>phone : {sliderFormData.phone}</p>
          <p>Description : {sliderFormData.description}</p>

          <button
            type="button"
            class="btn btn-danger btn-sm"
            onClick={() => deleteMessage(sliderFormData.id)}
            title="Delete"
          >
            <i class="fa fa-trash-o"></i>
          </button>
        </div>
      )}
    </>
  );
};

export default ViewSliderMessages;
