import React, { useContext, useEffect, useState } from "react";

import { authContext } from "../Helpers/AuthContext";
import { Formik, Form, Field } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";

const Cta = () => {
  const { authState } = useContext(authContext);

  const [businessData, setBusinessData] = useState({
    heading: "",
    button1Name: "",
    button1Link: "",
    button1Icon: "",
    button2Name: "",
    button2Link: "",
    button2Icon: "",
  });

  useEffect(() => {
    axios
      .get("https://backend.seo-calgary.ca/api/v1/dashboards/getCta")
      .then((res) => {
        if (res.data.error) {
          toast.error(res.data.error);
        } else {
          setBusinessData(res.data.data);
        }
      });
  }, []);

  return (
    <div class="row clearfix">
      <div class="col-md-6">
        <div class="card">
          <div class="header">
            <h2>{businessData.heading !== "" ? "Update" : "Add"} Content</h2>
          </div>
          <div class="body">
            <Formik
              enableReinitialize
              initialValues={businessData && businessData}
              onSubmit={(values) => {
                console.log("submit", values);
                
                  if (businessData.heading !== "") {
                    values.updated_by = authState.name;
                    axios
                      .post(
                        `https://backend.seo-calgary.ca/api/v1/dashboards/updateCta`,
                        values,
                        {
                          headers: {
                            accessToken: localStorage.getItem("authtoken"),
                          },
                        }
                      )
                      .then((res) => {
                        if (res.data.error) {
                          toast.error(res.data.error);
                        } else {
                          toast.success(res.data.message);
                        }
                      });
                  } else {
                    values.created_By = authState.name;
                    axios
                      .post(
                        "https://backend.seo-calgary.ca/api/v1/dashboards/addCta",
                        values,
                        {
                          headers: {
                            accessToken: localStorage.getItem("authtoken"),
                          },
                        }
                      )
                      .then((res) => {
                        if (res.data.error) {
                          toast.error(res.data.error);
                        } else {
                          toast.success(res.data.message);
                        }
                      });
                  }
              }}
            >
              {({ setFieldValue, values }) => {
                return (
                  <>
                    <Form id="basic-form">                      
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="heading">Heading: </label>
                        <Field
                          type="text"
                          name="heading"
                          id="heading"
                          class="form-control"
                          required
                        />
                      </div>                   
                     
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="button1Name">Button1 Name:</label>
                        <Field
                          type="text"
                          name="button1Name"
                          id="button1Name"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="button1Link">Button1 Link: </label>
                        <Field
                          type="text"
                          name="button1Link"
                          id="button1Link"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="button1Icon">Button1 Icon: </label>
                        <Field
                          type="text"
                          name="button1Icon"
                          id="button1Icon"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="button2Name">Button2 Name: </label>
                        <Field
                          type="text"
                          name="button2Name"
                          id="button2Name"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="button2Link">Button2 Link: </label>
                        <Field
                          type="text"
                          name="button2Link"
                          id="button2Link"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="button2Icon">Button2 Icon: </label>
                        <Field
                          type="text"
                          name="button2Icon"
                          id="button2Icon"
                          class="form-control"
                          required
                        />
                      </div>

                      <Field
                        type="submit"
                        value={businessData.heading !== "" ? "update" : "Add"}
                        class="btn btn-primary"
                      />
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Cta;
