import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PackageTabListContent = () => {
  const { id } = useParams();
  const [dataChange, setDataChange] = useState(false);
  const [contentData, setContentData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(
          `https://backend.seo-calgary.ca/api/v1/dashboards/getOurPackageTabListContentDataById/${id}`
        )
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            console.log(res.data.data);
            setContentData(res.data.data);
          }
        });
    };
    fetchData();
  }, [dataChange, id]);

  console.log("contentData", contentData);

  const handleDeleteSlide = async (id) => {
    console.log(id);
    if (window.confirm("Are you sure to Delete this Content?")) {
      await axios
        .get(
          `https://backend.seo-calgary.ca/api/v1/dashboards/deleteOurPackageTabListContent/${id}`,
          {
            headers: {
              accessToken: localStorage.getItem("authtoken"),
            },
          }
        )
        .then((res) => {
          if (res.data.error) {
            toast.error(res.data.error);
          } else {
            setDataChange(!dataChange);
            toast.success(res.data.message);
          }
        });
    }
  };

  const visiblityActions = async (element) => {
    console.log(element);
    if (element.isPublished === 0) {
      if (window.confirm("Are you sure to Publish this Content?")) {
        await axios
          .get(
            `https://backend.seo-calgary.ca/api/v1/dashboards/publishOurPackageTabListContent/${element.id}`,
            {
              headers: {
                accessToken: localStorage.getItem("authtoken"),
              },
            }
          )
          .then((res) => {
            if (res.data.error) {
              toast.error(res.data.error);
            } else {
              setDataChange(!dataChange);
              toast.success(res.data.message);
            }
          });
      }
    } else {
      if (window.confirm("Are you sure to Unpublish this Content?")) {
        await axios
          .get(
            `https://backend.seo-calgary.ca/api/v1/dashboards/unPublishOurPackageTabListContent/${element.id}`,
            {
              headers: {
                accessToken: localStorage.getItem("authtoken"),
              },
            }
          )
          .then((res) => {
            if (res.data.error) {
              toast.error(res.data.error);
            } else {
              setDataChange(!dataChange);
              toast.success(res.data.message);
            }
          });
      }
    }
  };
  return (
    <>
      <div class="row clearfix">
        <div class="col-lg-12">
          <div class="card">
            <div class="header">
              <h2>Contents</h2>
              <Link
                to={`/add-package-tab-list-content/${id}`}
                className="btn btn-primary"
              >
                Add Content
              </Link>
            </div>
            {contentData && contentData.length > 0 ? (
              <div class="body">
                <div class="table-responsive">
                  <table class="table table-hover mb-0 c_list">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Published ?</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contentData &&
                        contentData.map((element, index) => {
                          return (
                            <>
                              <tr key={index}>
                                <td>
                                  <span>{element.title}</span>
                                </td>
                                <td>
                                  <span>
                                  {element.description}
                                  </span>
                                </td>
                                <td>
                                  {element.isPublished === 0 ? (
                                    <span style={{ color: "red" }}>
                                      Not Published
                                    </span>
                                  ) : (
                                    <span style={{ color: "green" }}>
                                      Published
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    class={`btn ${
                                      element.isPublished === 0
                                        ? "btn-primary"
                                        : "btn-warning"
                                    } btn-sm mr-2`}
                                    onClick={() => {
                                      visiblityActions(element);
                                    }}
                                    title={
                                      element.isPublished === 0
                                        ? "Publish"
                                        : "Unpublish"
                                    }
                                  >
                                    {element.isPublished === 0 ? (
                                      <i class="fa fa-eye"></i>
                                    ) : (
                                      <i class="fa fa-eye-slash"></i>
                                    )}
                                  </button>
                                  <Link
                                    to={`/edit-package-tab-list-content/${id}/${element.id}`}
                                    class="btn btn-info btn-sm mr-2"
                                    title="View & Edit"
                                  >
                                    <i class="fa fa-edit"></i>
                                  </Link>
                                  <button
                                    type="button"
                                    class="btn btn-danger btn-sm mr-2"
                                    onClick={() => {
                                      handleDeleteSlide(element.id);
                                    }}
                                    title="Delete"
                                  >
                                    <i class="fa fa-trash-o"></i>
                                  </button>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <h4>No data Found</h4>
            )}
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default PackageTabListContent;
