import React, { useContext, useEffect, useState } from "react";
import Logo from "../../images/brand/icon.svg";

import { menuContext } from "../../Helpers/MenuContext";
import { authContext } from "../../Helpers/AuthContext";

import { useLocation } from "react-router-dom";

import { useNavigate } from "react-router";
import axios from "axios";
import { Link } from "react-router-dom";
import BreadCrumb from "../BreadCrumb/BreadCrumb";

const Topbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  const [siteSettingData, setSiteSettingData] = useState();

  const { isMenuOpen, setIsMenuOpen } = useContext(menuContext);
  const { setAuthState, change, setChanged } = useContext(authContext);

  const handelLogout = () => {
    setAuthState({ id: "", name: "", email: "", status: false });
    localStorage.removeItem("authtoken");
    setChanged(!change);
    navigate("/");
  };

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get("https://backend.seo-calgary.ca/api/v1/settings/getSettings")
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            console.log(res.data);
            setSiteSettingData(res.data.data);
            console.log(res.data.message);
          }
        });
    };
    fetchData();
  }, []);

  return (
    <nav class="navbar custom-navbar navbar-expand-lg py-2">
      <div class="container-fluid px-0">
        <button
          type="button"
          style={{ border: 0, backgroundColor: "transparent" }}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          class="menu_toggle"
        >
          <i class="fa fa-align-left"></i>
        </button>
        <Link to="/" class="navbar-brand">
          {siteSettingData && siteSettingData.logo ? (
            <img
              src={`https://backend.seo-calgary.ca/${siteSettingData.logo}`}
              alt={siteSettingData && siteSettingData.comapny_name}
            />
          ) : (
            <>
              <strong>{siteSettingData && siteSettingData.comapny_name}</strong>
            </>
          )}
        </Link>
        <div id="navbar_main">
          <ul class="navbar-nav mr-auto hidden-xs">
            <li class="nav-item page-header">
             <BreadCrumb link={location.pathname}/>
            </li>
          </ul>
          <ul class="navbar-nav">
            <li class="nav-item">
              <Link to="/change-password">
                <i class="fa fa-cogs"></i>
              </Link>
            </li>
            <li class="nav-item ml-4">
              <button
                type="button"
                className="btn-primary"
                onClick={handelLogout}
              >
                Log Out
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Topbar;
