import React, { useEffect, useState } from "react";

import { Formik, Form, Field } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { useNavigate, useParams } from "react-router";

const AddIndustrySlide = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [industrySlider, setIndustrySlider] = useState();

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(
          `https://backend.seo-calgary.ca/api/v1/dashboards/getIndustrySliderSlideById/${id}`,
          {
            headers: {
              accessToken: localStorage.getItem("authtoken"),
            },
          }
        )
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            console.log(res.data.data);
            setIndustrySlider(res.data.slideData);
          }
        });
    };
    fetchData();
  }, []);

  console.log(industrySlider);

  return (
    <div class="row clearfix">
      <div class="col-md-6">
        <div class="card">
          <div class="header">
            <h2>Add Slide</h2>
          </div>
          <div class="body">
            <Formik
              enableReinitialize
              initialValues={industrySlider}
              onSubmit={(values) => {
                console.log("submit", values);
                axios
                  .post(
                    `https://backend.seo-calgary.ca/api/v1/dashboards/updateIndustrySlideById/${id}`,
                    values,
                    {
                      headers: {
                        accessToken: localStorage.getItem("authtoken"),
                      },
                    }
                  )
                  .then((res) => {
                    if (res.data.error) {
                      toast.error(res.data.error);
                    } else {
                      toast.success(res.data.message);
                      navigate("/industry-sliders");
                    }
                  });
              }}
              
            >
              {({ setFieldValue, values }) => {
                return (
                  <>
                    <Form id="basic-form">
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="title">Title :</label>
                        <Field
                          type="text"
                          name="title"
                          id="title"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="link">Link: </label>
                        <Field
                          type="text"
                          name="link"
                          id="link"
                          class="form-control"
                          required
                        />
                      </div>
                      <Field
                        type="submit"
                        value="Submit"
                        class="btn btn-primary"
                      />
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default AddIndustrySlide;
