import React, { useContext, useEffect, useRef, useState } from "react";

import { authContext } from "../../Helpers/AuthContext";
import { Formik, Form, Field } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import PreviewImage from "../../Helpers/PreviewImage";

import axios from "axios";
import { useNavigate, useParams } from "react-router";

const AddOurWorkTabList = () => {
  const { authState } = useContext(authContext);
  const navigate = useNavigate();

  const { id } = useParams();

  const initialValues = {
    name: "",
    tabId: "",
    bsTarget: "",
    ariaControls: "",
    isActive: null,
  };

  const [tabData, setTabData] = useState(null);
  console.log(tabData);
  const [anyTabListActive, setAnyTabListActive] = useState(false);

  useEffect(() => {
    if (id) {
      axios
        .get(
          `https://backend.seo-calgary.ca/api/v1/dashboards/WorkTabListDataById/${id}`
        )
        .then((res) => {
          if (res.data.error) {
            toast.error(res.data.error);
          } else {
            setTabData(res.data.data);
          }
        });
    }
    axios
      .get(`https://backend.seo-calgary.ca/api/v1/dashboards/isAnyTabListActive`)
      .then((res) => {
        setAnyTabListActive(res.data);
      });
  }, [id]);

  return (
    <div class="row clearfix">
      <div class="col-md-6">
        <div class="card">
          <div class="header">
            <h2>{tabData ? "Update" : "Add"} TabList</h2>
          </div>
          <div class="body">
            <Formik
              enableReinitialize
              initialValues={tabData ? tabData : initialValues}
              onSubmit={(values) => {
                console.log("submit", values);
                if (tabData) {
                  values.updated_by = authState.name;
                  axios
                    .post(
                      `https://backend.seo-calgary.ca/api/v1/dashboards/updateWorkTabListById/${id}`,
                      values,
                      {
                        headers: {
                          accessToken: localStorage.getItem("authtoken"),
                        },
                      }
                    )
                    .then((res) => {
                      if (res.data.error) {
                        toast.error(res.data.error);
                      } else {
                        toast.success(res.data.message);
                        navigate("/our-works");
                      }
                    });
                } else {
                  values.created_by = authState.name;
                  values.OurWorkId = 1;
                  values.ariaSelected = values.isActive ? "true" : "false";
                  axios
                    .post(
                      "https://backend.seo-calgary.ca/api/v1/dashboards/addWorkTabList",
                      values,
                      {
                        headers: {
                          accessToken: localStorage.getItem("authtoken"),
                        },
                      }
                    )
                    .then((res) => {
                      if (res.data.error) {
                        toast.error(res.data.error);
                      } else {
                        toast.success(res.data.message);
                        navigate("/our-works");
                      }
                    });
                }
              }}
            >
              {({ setFieldValue, values }) => {
                return (
                  <>
                    <Form id="basic-form">
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="name">TabName :</label>
                        <Field
                          type="text"
                          name="name"
                          id="name"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="tabId">TabId: </label>
                        <Field
                          type="text"
                          name="tabId"
                          id="tabId"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="bsTarget">BS Target: </label>
                        <Field
                          type="text"
                          name="bsTarget"
                          id="bsTarget"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="ariaControls">Aria Controls :</label>
                        <Field
                          type="text"
                          name="ariaControls"
                          id="ariaControls"
                          class="form-control"
                          required
                        />
                      </div>
                      {!tabData && (
                        <div class="form-group">
                          {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                          <label htmlFor="isActive">
                            Make it{" "}
                            {tabData && tabData.isActive === 1
                              ? "InActive"
                              : "Active"}
                          </label>

                          <Field
                            type="checkbox"
                            name="isActive"
                            id="isActive"
                            class=""
                            disabled={anyTabListActive ? true : false}
                            required
                          />
                        </div>
                      )}
                      <Field
                        type="submit"
                        value={tabData ? "update" : "Add"}
                        class="btn btn-primary"
                      />
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default AddOurWorkTabList;
