import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import axios from "axios";
import { Link } from "react-router-dom";

//Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { Link } from "react-router-dom";
import OurPackageActionTable from "../Components/Tables/OurPackageActionTable";
import PreviewImage from "../Helpers/PreviewImage";

const OurPackages = () => {
  const imageRef = useRef(null);

  const [ourPackage, setOurPackage] = useState({
    heading: "",
    backgroundImage: "",
    description: "",
    button1Name: "",
    button2Name: "",
    button1Link: "",
    button2Link: "",
    button1Icon: "",
    button2Icon: "",
    tabListData: [],
  });

  // const [formInfoData, setFormInfoData] = useState();

  const [dataChange, setDataChange] = useState(false);
  const [sliderDataChange, setSliderDataChange] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get("https://backend.seo-calgary.ca/api/v1/dashboards/getOurPackageData")
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            console.log(res.data.data);
            setOurPackage(res.data.data);
          }
        });
    };
    fetchData();
  }, [dataChange]);
  const [anyTabListActive, setAnyTabListActive] = useState(false);

  useEffect(() => {
    axios
      .get(`https://backend.seo-calgary.ca/api/v1/dashboards/isAnyPackageTabListActive`)
      .then((res) => {
        setAnyTabListActive(res.data);
      });
  }, [dataChange]);

  const handleDeleteSlide = async (id) => {
    console.log(id);
    if (window.confirm("Are you sure to Delete this Tab?")) {
      await axios
        .get(
          `https://backend.seo-calgary.ca/api/v1/dashboards/deleteOurPackageTabList/${id}`,
          {
            headers: {
              accessToken: localStorage.getItem("authtoken"),
            },
          }
        )
        .then((res) => {
          if (res.data.error) {
            toast.error(res.data.error);
          } else {
            setDataChange(!dataChange);
            toast.success(res.data.message);
          }
        });
    }
  };

  const visiblityActions = async (element) => {
    console.log(element);
    if (element.isPublished === 0) {
      if (window.confirm("Are you sure to Publish this PAckage?")) {
        await axios
          .get(
            `https://backend.seo-calgary.ca/api/v1/dashboards/publishOurPackageTabList/${element.id}`,
            {
              headers: {
                accessToken: localStorage.getItem("authtoken"),
              },
            }
          )
          .then((res) => {
            if (res.data.error) {
              toast.error(res.data.error);
            } else {
              setDataChange(!dataChange);
              toast.success(res.data.message);
            }
          });
      }
    } else {
      if (window.confirm("Are you sure to Unpublish this PAckage?")) {
        await axios
          .get(
            `https://backend.seo-calgary.ca/api/v1/dashboards/unPublishOurPackageTabList/${element.id}`,
            {
              headers: {
                accessToken: localStorage.getItem("authtoken"),
              },
            }
          )
          .then((res) => {
            if (res.data.error) {
              toast.error(res.data.error);
            } else {
              setDataChange(!dataChange);
              toast.success(res.data.message);
            }
          });
      }
    }
  };
  const activeDeactive = async (element) => {
    console.log(element);
    let actionWord, value;
    if (anyTabListActive === true) {
      actionWord = "deactive";
      value = 0;
    } else {
      actionWord = "activate";
      value = 1;
    }

    if (window.confirm(`Are you sure to ${actionWord} this tab?`)) {
      await axios
        .get(
          `https://backend.seo-calgary.ca/api/v1/dashboards/activeDeactivePackageTabById/${element.id}/${value}`,
          {
            headers: {
              accessToken: localStorage.getItem("authtoken"),
            },
          }
        )
        .then((res) => {
          if (res.data.error) {
            toast.error(res.data.error);
          } else {
            setDataChange(!dataChange);
            toast.success(res.data.message);
          }
        });
    }
  };

  return (
    <>
      <div class="row clearfix">
        <div class="col-lg-12">
          <div class="card">
            <div class="header">
              <h2>Our Package</h2>
            </div>
            <div class="body">
              <Formik
                enableReinitialize
                initialValues={
                  ourPackage.heading !== ""
                    ? { ...ourPackage, backgroundImage: "" }
                    : { ourPackage }
                }
                onSubmit={(values) => {
                  console.log("submit", values);

                  const formData = new FormData();

                  formData.append("heading", values.heading);
                  formData.append("description", values.description);
                  formData.append("button1Name", values.button1Name);
                  formData.append("button1Link", values.button1Link);
                  formData.append("button1Icon", values.button1Icon);
                  formData.append("button2Name", values.button2Name);
                  formData.append("button2Link", values.button2Link);
                  formData.append("button2Icon", values.button2Icon);

                  if (ourPackage.heading !== "") {
                    if (values.backgroundImage === "") {
                      axios
                        .post(
                          "https://backend.seo-calgary.ca/api/v1/dashboards/updateOurPackageWithoutImage",
                          values,
                          {
                            headers: {
                              accessToken: localStorage.getItem("authtoken"),
                            },
                          }
                        )
                        .then((res) => {
                          if (res.data.error) {
                            toast.error(res.data.error);
                          } else {
                            toast.success(res.data.message);
                            setSliderDataChange(!sliderDataChange);
                          }
                        });
                    } else {
                      formData.append(
                        "backgroundImage",
                        values.backgroundImage
                      );
                      for (var pair of formData.entries()) {
                        console.log(pair[0] + ", " + pair[1]);
                      }
                      axios
                        .post(
                          "https://backend.seo-calgary.ca/api/v1/dashboards/updateOurPackageData",
                          formData,
                          {
                            headers: {
                              accessToken: localStorage.getItem("authtoken"),
                            },
                          }
                        )
                        .then((res) => {
                          if (res.data.error) {
                            toast.error(res.data.error);
                          } else {
                            toast.success(res.data.message);
                            setSliderDataChange(!sliderDataChange);
                          }
                        });
                    }
                  } else {
                    formData.append("backgroundImage", values.backgroundImage);
                    axios
                      .post(
                        "https://backend.seo-calgary.ca/api/v1/dashboards/addOurPackageData",
                        formData,
                        {
                          headers: {
                            accessToken: localStorage.getItem("authtoken"),
                          },
                        }
                      )
                      .then((res) => {
                        if (res.data.error) {
                          toast.error(res.data.error);
                        } else {
                          toast.success(res.data.message);
                          setDataChange(!sliderDataChange);
                        }
                      });
                  }
                }}
              >
                {({ setFieldValue, values }) => {
                  return (
                    <>
                      <Form id="basic-form">
                        <div className="row">
                          <div class="form-group col-md-4">
                            <div class="form-group">
                              {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                              <label htmlFor="heading">Heading :</label>
                              <Field
                                type="text"
                                name="heading"
                                id="heading"
                                class="form-control"
                                required
                              />
                            </div>
                            <div class="form-group">
                              {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                              <label htmlFor="description">Description :</label>
                              <Field
                                type="text"
                                name="description"
                                id="description"
                                class="form-control"
                                required
                              />
                            </div>
                            <div class="form-group">
                              <label htmlFor="backgroundImage">
                                BackgroundImage :{" "}
                                {
                                  <span className="error-message">
                                    <ErrorMessage name="backgroundImage" />
                                  </span>
                                }{" "}
                              </label>
                              {values.backgroundImage ? (
                                <PreviewImage file={values.backgroundImage} />
                              ) : ourPackage.backgroundImage !== "" ? (
                                <PreviewImage
                                  file={`https://backend.seo-calgary.ca/${ourPackage.backgroundImage}`}
                                />
                              ) : null}
                              <button
                                className="btn-primary"
                                type="button"
                                onClick={() => {
                                  imageRef.current.click();
                                }}
                              >
                                {values.backgroundImage ||
                                ourPackage.backgroundImage
                                  ? "Edit Image"
                                  : "Upload Image"}
                              </button>
                              <input
                                ref={imageRef}
                                type="file"
                                id="backgroundImage"
                                class="form-control"
                                onChange={(e) => {
                                  setFieldValue(
                                    "backgroundImage",
                                    e.target.files[0]
                                  );
                                }}
                                hidden
                              />
                            </div>
                          </div>
                          <div class="form-group col-md-4">
                            <div class="form-group">
                              {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                              <label htmlFor="button1Name">
                                Button1 Name :
                              </label>
                              <Field
                                type="text"
                                name="button1Name"
                                id="button1Name"
                                class="form-control"
                                required
                              />
                            </div>
                            <div class="form-group">
                              {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                              <label htmlFor="button1Link">
                                Button1 Link :
                              </label>
                              <Field
                                type="text"
                                name="button1Link"
                                id="button1Link"
                                class="form-control"
                                required
                              />
                            </div>
                            <div class="form-group">
                              {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                              <label htmlFor="button1Icon">
                                Button1 Icon :
                              </label>
                              <Field
                                type="text"
                                name="button1Icon"
                                id="button1Icon"
                                class="form-control"
                                required
                              />
                            </div>
                          </div>
                          <div class="form-group col-md-4">
                            <div class="form-group">
                              {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                              <label htmlFor="button2Name">
                                Button2 Name :
                              </label>
                              <Field
                                type="text"
                                name="button2Name"
                                id="button2Name"
                                class="form-control"
                                required
                              />
                            </div>
                            <div class="form-group">
                              {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                              <label htmlFor="button2Link">
                                Button2 Link :
                              </label>
                              <Field
                                type="text"
                                name="button2Link"
                                id="button2Link"
                                class="form-control"
                                required
                              />
                            </div>
                            <div class="form-group">
                              {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                              <label htmlFor="button2Icon">
                                Button2 Icon :
                              </label>
                              <Field
                                type="text"
                                name="button2Icon"
                                id="button2Icon"
                                class="form-control"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <Field
                          type="submit"
                          value={ourPackage.heading !== "" ? "Update" : "Submit"}
                          class="btn btn-primary"
                        />
                      </Form>
                    </>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <div class="row clearfix">
        <div class="col-lg-12">
          <div class="card">
            <div class="header">
              <h2>Tab Lists</h2>
              <Link to="/add-our-package-tab-List" className="btn btn-primary">
                Add TabList
              </Link>
            </div>
            <OurPackageActionTable
              data={ourPackage.tabListData}
              activeDeactive={activeDeactive}
              anyTabListActive={anyTabListActive}
              handleDeleteSlide={handleDeleteSlide}
              visiblityActions={visiblityActions}
            />
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default OurPackages;
