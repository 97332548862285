import React, { useContext, useRef } from "react";

import { authContext } from "../../Helpers/AuthContext";
import { Formik, Form, Field } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import PreviewImage from "../../Helpers/PreviewImage";

import axios from "axios";
import { useNavigate } from "react-router";

const AddTechnoSlide = () => {
  const { authState } = useContext(authContext);
  const navigate = useNavigate()

  const sliderImageRef = useRef(null);

  let slideData = null;

  const initialValues = {
    heading: "",
    sliderImage: "",
  };

  return (
    <div class="row clearfix">
      <div class="col-md-6">
        <div class="card">
          <div class="header">
            <h2>Add Slide</h2>
          </div>
          <div class="body">
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                console.log("submit", values);

                const formData = new FormData();

                formData.append("heading" , values.heading);
                formData.append("created_By" , authState.name);
                formData.append("sliderImage" , values.sliderImage);

                axios
                  .post(
                    "https://backend.seo-calgary.ca/api/v1/dashboards/addTechnoSlider",
                    formData,
                    {
                      headers: {
                        accessToken: localStorage.getItem("authtoken"),
                      },
                    }
                  )
                  .then((res) => {
                    if (res.data.error) {
                      toast.error(res.data.error);
                    } else {
                      toast.success(res.data.message);
                      navigate('/techno-sliders');
                    }
                  });
              }}
            >
              {({ setFieldValue, values }) => {
                return (
                  <>
                    <Form id="basic-form">
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="heading">Heading :</label>
                        <Field
                          type="text"
                          name="heading"
                          id="heading"
                          class="form-control"
                          required
                        />
                        </div>
                      <div class="form-group">
                        <label htmlFor="sliderImage">Slider Image :</label>
                        {values.sliderImage && values.sliderImage ? (
                          <PreviewImage file={values.sliderImage} />
                        ) : slideData && slideData.sliderImage !== "" ? (
                          <PreviewImage
                            file={`https://backend.seo-calgary.ca/${slideData.sliderImage}`}
                          />
                        ) : null}
                        <button
                          className="btn-primary"
                          type="button"
                          onClick={() => {
                            sliderImageRef.current.click();
                          }}
                        >
                          {values.sliderImage ||
                          (slideData && slideData.sliderImage)
                            ? "Edit Image"
                            : "Upload Image"}
                        </button>
                        <input
                          ref={sliderImageRef}
                          type="file"
                          id="sliderImage"
                          class="form-control"
                          onChange={(e) => {
                            setFieldValue("sliderImage", e.target.files[0]);
                          }}
                          hidden
                        />
                      </div>
                      <Field
                        type="submit"
                        value="Submit"
                        class="btn btn-primary"
                      />
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default AddTechnoSlide;
