import React, { useContext, useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { authContext } from "../Helpers/AuthContext";

import axios from "axios";

//Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import IndustrySliderActionTable from "../Components/Tables/IndustrySliderActionTable";
import PreviewImage from "../Helpers/PreviewImage";

const IndustrySliders = () => {
  const { authState } = useContext(authContext);

  const imageRef = useRef(null);

  const [industrySlider, setIndustrySlider] = useState({
    heading: "",
    backgroundImage: "",
    slideData: [],
  });

  // const [formInfoData, setFormInfoData] = useState();

  const [dataChange, setDataChange] = useState(false);
  const [sliderDataChange, setSliderDataChange] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get("https://backend.seo-calgary.ca/api/v1/dashboards/getIndustrySlider")
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            console.log(res.data.data);
            setIndustrySlider(res.data.data);
          }
        });
    };
    fetchData();
  }, [dataChange]);

  console.log(industrySlider);

  const handleDeleteSlide = async (id) => {
    console.log(id);
    if (window.confirm("Are you sure to Delete this Slide?")) {
      await axios
        .get(
          `https://backend.seo-calgary.ca/api/v1/dashboards/deleteIndustrySlide/${id}`,
          {
            headers: {
              accessToken: localStorage.getItem("authtoken"),
            },
          }
        )
        .then((res) => {
          if (res.data.error) {
            toast.error(res.data.error);
          } else {
            setDataChange(!dataChange);
            toast.success(res.data.message);
          }
        });
    }
  };

  const visiblityActions = async (element) => {
    console.log(element);
    if (element.isPublished === 0) {
      if (window.confirm("Are you sure to Publish this Slide?")) {
        await axios
          .get(
            `https://backend.seo-calgary.ca/api/v1/dashboards/publishIndustrySlide/${element.id}`,
            {
              headers: {
                accessToken: localStorage.getItem("authtoken"),
              },
            }
          )
          .then((res) => {
            if (res.data.error) {
              toast.error(res.data.error);
            } else {
              setDataChange(!dataChange);
              toast.success(res.data.message);
            }
          });
      }
    } else {
      if (window.confirm("Are you sure to Unpublish this Slide?")) {
        await axios
          .get(
            `https://backend.seo-calgary.ca/api/v1/dashboards/unPublishIndustrySlide/${element.id}`,
            {
              headers: {
                accessToken: localStorage.getItem("authtoken"),
              },
            }
          )
          .then((res) => {
            if (res.data.error) {
              toast.error(res.data.error);
            } else {
              setDataChange(!dataChange);
              toast.success(res.data.message);
            }
          });
      }
    }
  };

  return (
    <>
      <div class="row clearfix">
        <div class="col-lg-12">
          <div class="card">
            <div class="header">
              <h2>Slider Form Heading</h2>
            </div>
            <div class="body">
              <div class="form-group col-md-6">
                <Formik
                  enableReinitialize
                  initialValues={
                    industrySlider.heading !== ""
                      ? { ...industrySlider, backgroundImage: "" }
                      : { industrySlider }
                  }
                  onSubmit={(values) => {
                    console.log("submit", values);

                    const formData = new FormData();

                    formData.append("heading", values.heading);
                    

                    if (industrySlider.heading !== "") {
                      if (values.backgroundImage === "") {
                        axios
                          .post(
                            "https://backend.seo-calgary.ca/api/v1/dashboards/updateIndustrySliderHeaderOnly",
                            values,
                            {
                              headers: {
                                accessToken: localStorage.getItem("authtoken"),
                              },
                            }
                          )
                          .then((res) => {
                            if (res.data.error) {
                              toast.error(res.data.error);
                            } else {
                              toast.success(res.data.message);
                              setSliderDataChange(!sliderDataChange);
                            }
                          });
                      } else {
                        formData.append("backgroundImage", values.backgroundImage);
                        for (var pair of formData.entries()) {
                          console.log(pair[0]+ ', ' + pair[1]); 
                      }
                        axios
                          .post(
                            "https://backend.seo-calgary.ca/api/v1/dashboards/updateIndustrySlider",
                            formData,
                            {
                              headers: {
                                accessToken: localStorage.getItem("authtoken"),
                              },
                            }
                          )
                          .then((res) => {
                            if (res.data.error) {
                              toast.error(res.data.error);
                            } else {
                              toast.success(res.data.message);
                              setSliderDataChange(!sliderDataChange);
                            }
                          });
                      }
                    } else {
                      formData.append("backgroundImage", values.backgroundImage);
                      axios
                        .post(
                          "https://backend.seo-calgary.ca/api/v1/dashboards/addIndustrySliderInfo",
                          formData,
                          {
                            headers: {
                              accessToken: localStorage.getItem("authtoken"),
                            },
                          }
                        )
                        .then((res) => {
                          if (res.data.error) {
                            toast.error(res.data.error);
                          } else {
                            toast.success(res.data.message);
                            setSliderDataChange(!sliderDataChange);
                          }
                        });
                    }
                  }}
                >
                  {({ setFieldValue, values }) => {
                    return (
                      <>
                        <Form id="basic-form">
                          <div class="form-group">
                            {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                            <label htmlFor="heading">Heading :</label>
                            <Field
                              type="text"
                              name="heading"
                              id="heading"
                              class="form-control"
                              required
                            />
                          </div>
                          <div class="form-group">
                            <label htmlFor="backgroundImage">
                              BackgroundImage :{" "}
                              {
                                <span className="error-message">
                                  <ErrorMessage name="backgroundImage" />
                                </span>
                              }{" "}
                            </label>
                            {values.backgroundImage ? (
                              <PreviewImage file={values.backgroundImage} />
                            ) : industrySlider.backgroundImage !== "" ? (
                              <PreviewImage
                                file={`https://backend.seo-calgary.ca/${industrySlider.backgroundImage}`}
                              />
                            ) : null}
                            <button
                              className="btn-primary"
                              type="button"
                              onClick={() => {
                                imageRef.current.click();
                              }}
                            >
                              {values.backgroundImage ||
                              industrySlider.backgroundImage
                                ? "Edit Image"
                                : "Upload Image"}
                            </button>
                            <input
                              ref={imageRef}
                              type="file"
                              id="backgroundImage"
                              class="form-control"
                              onChange={(e) => {
                                setFieldValue(
                                  "backgroundImage",
                                  e.target.files[0]
                                );
                              }}
                              hidden
                            />
                          </div>
                          <Field
                            type="submit"
                            value={
                              industrySlider.heading !== ""
                                ? "Update"
                                : "Submit"
                            }
                            class="btn btn-primary"
                          />
                        </Form>
                      </>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row clearfix">
        <div class="col-lg-12">
          <div class="card">
            <div class="header">
              <h2>Industry Slider</h2>
              <Link to="/add-industry-slide" className="btn btn-primary">
                Add Slide
              </Link>
            </div>
            <IndustrySliderActionTable
              data={industrySlider.slideData}
              handleDeleteSlide={handleDeleteSlide}
              visiblityActions={visiblityActions}
            />
          </div>
        </div>

        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  );
};

export default IndustrySliders;
