import React, { useContext, useEffect, useRef, useState } from "react";

import { authContext } from "../../Helpers/AuthContext";
import { Formik, Form, Field } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import PreviewImage from "../../Helpers/PreviewImage";

import axios from "axios";
import { useNavigate, useParams } from "react-router";

const AddTestimonialCard = () => {
  const { authState } = useContext(authContext);

  const { id } = useParams();
  const navigate = useNavigate();

  const imageRef = useRef(null);

  const [cardData, setCardData] = useState({
    image: "",
    description: "",
    reviewer: "",
    location: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(
          `https://backend.seo-calgary.ca/api/v1/dashboards/getTestimonialSlideById/${id}`
        )
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            console.log(res.data);
            setCardData(res.data.data);
          }
        });
    };
    if (id) {
      fetchData();
    }
  }, [id]);

  return (
    <div class="row clearfix">
      <div class="col-md-6">
        <div class="card">
          <div class="header">
            <h2>Add Card</h2>
          </div>
          <div class="body">
            <Formik
              enableReinitialize
              initialValues={
                cardData.description !== ""
                  ? { ...cardData, image: "" }
                  : cardData
              }
              onSubmit={(values) => {
                console.log("submit", values);

                const formData = new FormData();
                formData.append("description", values.description);
                formData.append("reviewer", values.reviewer);
                formData.append("location", values.location);

                if (cardData.description !== "") {
                  if (values.image === "") {
                    values.updated_by = authState.name;
                    axios
                      .post(
                        `https://backend.seo-calgary.ca/api/v1/dashboards/updateTestimonialSlideByIdWithoutPicture/${id}`,
                        formData,
                        {
                          headers: {
                            accessToken: localStorage.getItem("authtoken"),
                          },
                        }
                      )
                      .then((res) => {
                        if (res.data.error) {
                          toast.error(res.data.error);
                        } else {
                          toast.success(res.data.message);
                          navigate("/testimonials");
                        }
                      });
                  } else {
                    formData.append("updated_by", authState.name);
                    formData.append("image", values.image);
                    axios
                      .post(
                        `https://backend.seo-calgary.ca/api/v1/dashboards/updateTestimonialSlideById/${id}`,
                        formData,
                        {
                          headers: {
                            accessToken: localStorage.getItem("authtoken"),
                          },
                        }
                      )
                      .then((res) => {
                        if (res.data.error) {
                          toast.error(res.data.error);
                        } else {
                          toast.success(res.data.message);
                          navigate("/testimonials");
                        }
                      });
                  }
                } else {
                  formData.append("created_By", authState.name);
                  formData.append("image", values.image);
                  axios
                    .post(
                      "https://backend.seo-calgary.ca/api/v1/dashboards/addTestimonialSlide",
                      formData,
                      {
                        headers: {
                          accessToken: localStorage.getItem("authtoken"),
                        },
                      }
                    )
                    .then((res) => {
                      if (res.data.error) {
                        toast.error(res.data.error);
                      } else {
                        toast.success(res.data.message);
                        navigate("/testimonials");
                      }
                    });
                }
              }}
            >
              {({ setFieldValue, values }) => {
                return (
                  <>
                    <Form id="basic-form">
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="description">Description :</label>
                        <Field
                          type="text"
                          name="description"
                          id="description"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="reviewer">Reviewer: </label>
                        <Field
                          type="text"
                          name="reviewer"
                          id="reviewer"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="location">Location: </label>
                        <Field
                          type="text"
                          name="location"
                          id="location"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        <label htmlFor="image">Image :</label>
                        {values.image && values.image ? (
                          <PreviewImage file={values.image} />
                        ) : cardData && cardData.image !== "" ? (
                          <PreviewImage
                            file={`https://backend.seo-calgary.ca/${cardData.image}`}
                          />
                        ) : null}
                        <button
                          className="btn-primary"
                          type="button"
                          onClick={() => {
                            imageRef.current.click();
                          }}
                        >
                          {values.image || (cardData && cardData.image)
                            ? "Edit Image"
                            : "Upload Image"}
                        </button>
                        <input
                          ref={imageRef}
                          type="file"
                          id="image"
                          class="form-control"
                          onChange={(e) => {
                            setFieldValue("image", e.target.files[0]);
                          }}
                          hidden
                        />
                      </div>
                      <Field
                        type="submit"
                        value="Submit"
                        class="btn btn-primary"
                      />
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default AddTestimonialCard;
