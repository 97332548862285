import React, { useContext, useEffect, useState } from "react";

import { authContext } from "../../Helpers/AuthContext";
import { Formik, Form, Field } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { useNavigate, useParams } from "react-router";

const AddWhyUsData = () => {
  const { authState } = useContext(authContext);
  const navigate = useNavigate();

  const { id } = useParams();

  const [whyUsData, setWhyUsData] = useState({
    icon: "",
    text: "",
  });
  console.log(whyUsData);

  useEffect(() => {
    if (id) {
      axios
        .get(`https://backend.seo-calgary.ca/api/v1/dashboards/getWhyUsDataById/${id}`)
        .then((res) => {
          if (res.data.error) {
            toast.error(res.data.error);
          } else {
            setWhyUsData(res.data.data);
          }
        });
    }
  }, [id]);

  return (
    <div class="row clearfix">
      <div class="col-md-6">
        <div class="card">
          <div class="header">
            <h2>{whyUsData.icon !== "" ? "Update" : "Add"} Content</h2>
          </div>
          <div class="body">
            <Formik
              enableReinitialize
              initialValues={whyUsData && whyUsData}
              onSubmit={(values) => {
                console.log("submit", values);

                if (whyUsData.icon !== "") {
                  values.updated_by = authState.name;
                  axios
                    .post(
                      `https://backend.seo-calgary.ca/api/v1/dashboards/updateWhyUsData`,
                      values,
                      {
                        headers: {
                          accessToken: localStorage.getItem("authtoken"),
                        },
                      }
                    )
                    .then((res) => {
                      if (res.data.error) {
                        toast.error(res.data.error);
                      } else {
                        toast.success(res.data.message);
                        navigate(`/why-us`);
                      }
                    });
                } else {
                  values.created_by = authState.name;
                  axios
                    .post(
                      "https://backend.seo-calgary.ca/api/v1/dashboards/addWhyUsData",
                      values,
                      {
                        headers: {
                          accessToken: localStorage.getItem("authtoken"),
                        },
                      }
                    )
                    .then((res) => {
                      if (res.data.error) {
                        toast.error(res.data.error);
                      } else {
                        toast.success(res.data.message);
                        navigate(`/why-us`);
                      }
                    });
                }
              }}
            >
              {({ setFieldValue, values }) => {
                return (
                  <>
                    <Form id="basic-form">
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="icon">Icon :</label>
                        <Field
                          type="text"
                          name="icon"
                          id="icon"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="text">Text: </label>
                        <Field
                          type="text"
                          name="text"
                          id="text"
                          class="form-control"
                          required
                        />
                      </div>
                      <Field
                        type="submit"
                        value={whyUsData.icon !== "" ? "update" : "Add"}
                        class="btn btn-primary"
                      />
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default AddWhyUsData;
