import React from "react";
import { Route, Routes } from "react-router";

import AddIndustrySlide from "../Components/IndusrtySlide/AddIndustrySlide";
import AddMainSlide from "../Components/MainSlide/AddMainSlide";
import AddOurPackageTabList from "../Components/OurPackage/AddOurPackageTabList";
import AddOurWorkTabList from "../Components/OurWork/AddOurWorkTabList";
import AddPackageTabListContent from "../Components/OurPackage/AddPackageTabListContent";
import AddProcessList from "../Components/process/AddProcessList";
import AddTabListContent from "../Components/OurWork/AddTabListContent";
import AddTechnoSlide from "../Components/TechnoSlide/AddTechnoSlide";
import AddTestimonialCard from "../Components/Testimonial/AddTestimonialCard";
import AddUser from "../Components/UserSystems/AddUser";
import AddWhyUsData from "../Components/WhyUs/AddWhyUsData";
import ChangePassword from "../Components/UserSystems/ChangePassword";
import Cta from "../Pages/Cta";
import DashboardHome from "../Pages/DashboardHome";
import EditIndustrySlide from "../Components/Forms/EditIndustrySlide";
import EditSlider from "../Components/MainSlide/EditSlider";
import EditTechnoSlider from "../Components/TechnoSlide/EditTechnoSlider";
import EditUser from "../Components/UserSystems/EditUser";
import Footer from "../Pages/Footer";
import GrowYourBusiness from "../Pages/GrowYourBusiness";
import HeaderTopLinks from "../Pages/HeaderTopLinks";
import IndustrySliders from "../Pages/IndustrySliders";
import LeadsData from "../Pages/LeadsData";
import MainSliders from "../Pages/MainSliders";
import MenuManagement from "../Pages/MenuManagement";
import OurPackages from "../Pages/OurPackages";
import OurServices from "../Pages/OurServices";
import OurWork from "../Pages/OurWork";
import PackageTabListContent from "../Components/OurPackage/PackageTabListContent";
import Process from "../Pages/Process";
import SiteSetting from "../Pages/SiteSetting";
import SliderFormData from "../Components/MainSlide/SliderFormData";
import TabListContent from "../Components/OurWork/TabListContent";
import TechnoSlider from "../Pages/TechnoSlider";
import Testimonials from "../Pages/Testimonials";
import UserManagement from "../Pages/UserManagement";
import ViewLeadMessages from "../Components/Leads/ViewLeadMessages";
import ViewSliderMessages from "../Components/MainSlide/ViewSliderMessages";
import WebPackages from "../Pages/WebPackages";
import WhyUs from "../Pages/WhyUs";
import PageData from "../Pages/PageData";

const AuthenticatedLayout = ({ authState, menuData }) => {
  return (
    <Routes>
      <Route path="/" exact element={<DashboardHome />} />
      <Route path="/site-setting" exact element={<SiteSetting />} />
      <Route path="/header-top-links" exact element={<HeaderTopLinks />} />
      <Route path="/menu-management" exact element={<MenuManagement />} />
      <Route path="/add-user" exact element={<AddUser />} />
      <Route path="/edit-user/:id" exact element={<EditUser />} />
      <Route path="/change-password" exact element={<ChangePassword />} />
      <Route path="/main-sliders" exact element={<MainSliders />} />
      <Route path="/add-main-slide" exact element={<AddMainSlide />} />
      <Route path="/view-edit-slider/:id" exact element={<EditSlider />} />
      <Route
        path="/view-edit-techno-slider/:id"
        exact
        element={<EditTechnoSlider />}
      />
      <Route path="/techno-sliders" exact element={<TechnoSlider />} />
      <Route path="/add-techno-slide" exact element={<AddTechnoSlide />} />
      <Route path="/our-services" exact element={<OurServices />} />
      <Route path="/industry-sliders" exact element={<IndustrySliders />} />
      <Route path="/add-industry-slide" exact element={<AddIndustrySlide />} />
      <Route
        path="/view-edit-industry-slider/:id"
        exact
        element={<EditIndustrySlide />}
      />
      <Route path="/our-works" exact element={<OurWork />} />
      <Route
        path="/add-our-work-tab-list"
        exact
        element={<AddOurWorkTabList />}
      />
      <Route
        path="/view-edit-tab_list/:id"
        exact
        element={<AddOurWorkTabList />}
      />
      <Route
        path="/view-edit-add-tab-list-content/:id"
        exact
        element={<TabListContent />}
      />
      <Route
        path="/add-tab-list-content/:tabId"
        exact
        element={<AddTabListContent />}
      />
      <Route
        path="/edit-tab-list-content/:tabId/:contentId"
        exact
        element={<AddTabListContent />}
      />

      {/* Our Packages */}
      <Route path="/our-packages" exact element={<OurPackages />} />
      <Route
        path="/add-our-package-tab-list"
        exact
        element={<AddOurPackageTabList />}
      />
      <Route
        path="/view-edit-package-tab-list/:id"
        exact
        element={<AddOurPackageTabList />}
      />
      <Route
        path="/view-edit-add-package-tab-list-content/:id"
        exact
        element={<PackageTabListContent />}
      />
      <Route
        path="/add-package-tab-list-content/:tabId"
        exact
        element={<AddPackageTabListContent />}
      />
      <Route
        path="/edit-package-tab-list-content/:tabId/:contentId"
        exact
        element={<AddPackageTabListContent />}
      />
      <Route path="/web-packages" exact element={<WebPackages />} />
      <Route path="/why-us" exact element={<WhyUs />} />
      <Route path="/add-why-us" exact element={<AddWhyUsData />} />
      <Route path="/edit-why-us/:id" exact element={<AddWhyUsData />} />
      <Route path="/grow-your-business" exact element={<GrowYourBusiness />} />
      {/* Process */}
      <Route path="/process" exact element={<Process />} />
      <Route path="/add-process-list" exact element={<AddProcessList />} />
      <Route path="/edit-process-list/:id" exact element={<AddProcessList />} />
      <Route path="/testimonials" exact element={<Testimonials />} />
      <Route
        path="/add-Testimonial-Card"
        exact
        element={<AddTestimonialCard />}
      />
      <Route
        path="/edit-Testimonial-Card/:id"
        exact
        element={<AddTestimonialCard />}
      />
      <Route path="/cta" exact element={<Cta />} />
      <Route path="/footer" exact element={<Footer />} />
      {menuData &&
        menuData.map((menu) => {
          return (
            <>
              <Route path={menu.route} exact element={<PageData data={menu}/>} />
            </>
          );
        })}
      {authState.role === "Super Admin" ? (
        <>
          <Route path="/user-management" exact element={<UserManagement />} />
          <Route path="/slider-messages" exact element={<SliderFormData />} />
          <Route
            path="/view-slider-message/:id"
            exact
            element={<ViewSliderMessages />}
          />
          <Route path="/leadsData" exact element={<LeadsData />} />
          <Route
            path="/view-leads-message/:id"
            exact
            element={<ViewLeadMessages />}
          />
        </>
      ) : null}
    </Routes>
  );
};

export default AuthenticatedLayout;
