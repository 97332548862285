import React, { useEffect, useState } from "react";

import { Routes, useLocation } from "react-router-dom";

import axios from "axios";
import { Helmet } from "react-helmet";
import { menuContext } from "../Helpers/MenuContext";
import { authContext } from "../Helpers/AuthContext";
import { ChangeLinkStructure } from "../Actions/ChangeLinkStructure";

import UnAuthLayout from "../Layouts/UnAuthLayout";
import AuthenticatedLayout from "../Layouts/AuthenticatedLayout";

import Topbar from "../Components/Common/Topbar";
import Sidebar from "../Components/Common/Sidebar";

function App() {
  // get from localstorage accessToken
  const token = localStorage.getItem("authtoken");

  // Close Menu State
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // get the location of the path
  const location = useLocation();
  const { link_name } = ChangeLinkStructure(location.pathname);

  // change screen with with scroll
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [authState, setAuthState] = useState({
    id: "",
    name: "",
    email: "",
    role: "",
    status: false,
  });
  const [change, setChanged] = useState(false);
  const [menuData, setMenuData] = useState(false);

  useEffect(() => {
    const handelWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handelWindowResize);

    return () => {
      window.removeEventListener("resize", handelWindowResize);
    };
  }, []);

  useEffect(() => {
    const authorizeUser = async () => {
      const user = await axios.get("https://backend.seo-calgary.ca/api/v1/users/auth", {
        headers: {
          accessToken: localStorage.getItem("authtoken"),
        },
      });
      if (user.data.error) {
        localStorage.removeItem("authtoken");
        setAuthState({ ...authState, status: false });
      } else {
        setAuthState({
          email: user.data.email,
          id: user.data.id,
          name: user.data.name,
          role: user.data.role,
          status: true,
        });
        console.log("step4");
      }
    };
    authorizeUser();
  }, [change]);

  useEffect(() => {
    const getMenus = async () => {
      const data = await axios.get(
        "https://backend.seo-calgary.ca/api/v1/Pages/getMenus",
        {
          headers: {
            accessToken: localStorage.getItem("authtoken"),
          },
        }
      );
      if (data.data.error) {
        console.log(data.data.error);
      } else {
        setMenuData(data.data);
      }
    };
    getMenus();
  }, []);

  return (
    <>
      <>
        <Helmet>
          <title>{link_name} || GlobalItech Admin</title>
        </Helmet>
        <authContext.Provider
          value={{ authState, setAuthState, change, setChanged }}
        >
          <menuContext.Provider value={{ isMenuOpen, setIsMenuOpen, menuData }}>
            <div
              className={
                windowWidth < 1201
                  ? "theme-indigo layout-fullwidth"
                  : "theme-indigo"
              }
            >
              {!token ? (
                <>
                  <UnAuthLayout />
                </>
              ) : (
                <>
                  <Topbar />
                  <div className="main_content" id="main-content">
                    <Sidebar />
                    <div class="page">
                      <div class="container-fluid">
                        <AuthenticatedLayout
                          authState={authState}
                          menuData={menuData}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </menuContext.Provider>
        </authContext.Provider>
      </>
    </>
  );
}

export default App;
