import React, { useContext, useEffect, useRef, useState } from "react";

import { authContext } from "../../Helpers/AuthContext";
import { Formik, Form, Field } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import PreviewImage from "../../Helpers/PreviewImage";

import axios from "axios";
import { useNavigate, useParams } from "react-router";

const AddProcessList = () => {
  const { id } = useParams();
  const { authState } = useContext(authContext);
  const navigate = useNavigate();

  const imageRef = useRef(null);

  const [listData, setListData] = useState({
    title: "",
    image: "",
    alt: "",
    description: "",
  });

  console.log(listData);

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        await axios
          .get(
            `https://backend.seo-calgary.ca/api/v1/dashboards/getProcessCardById/${id}`
          )
          .then((res) => {
            if (res.data.error) {
              window.alert(res.data.error);
            } else {
              console.log(res.data.listData);
              setListData(res.data.listData);
            }
          });
      };
      fetchData();
    }
  }, [id]);
  return (
    <div class="row clearfix">
      <div class="col-md-6">
        <div class="card">
          <div class="header">
            <h2>
              {listData && listData.title !== "" ? "Update" : "Add"} Slide
            </h2>
          </div>
          <div class="body">
            <Formik
              enableReinitialize
              initialValues={
                listData && listData.image !== ""
                  ? { ...listData, image: "" }
                  : listData
              }
              onSubmit={(values) => {
                console.log("submit", values);

                const formData = new FormData();

                formData.append("title", values.title);
                formData.append("description", values.description);
                formData.append("alt", values.alt);

                if (listData.title !== "") {
                  if (values.image === "") {
                    values.updated_by = authState.name;
                    axios
                      .post(
                        `https://backend.seo-calgary.ca/api/v1/dashboards/updateProcessListByIdwithoutImage/${id}`,
                        values,
                        {
                          headers: {
                            accessToken: localStorage.getItem("authtoken"),
                          },
                        }
                      )
                      .then((res) => {
                        if (res.data.error) {
                          toast.error(res.data.error);
                        } else {
                          toast.success(res.data.message);
                          navigate("/process");
                        }
                      });
                  } else {
                    formData.append("updated_by", authState.name);
                    formData.append("image", values.image);
                    axios
                      .post(
                        `https://backend.seo-calgary.ca/api/v1/dashboards/updateProcessListById/${id}`,
                        formData,
                        {
                          headers: {
                            accessToken: localStorage.getItem("authtoken"),
                          },
                        }
                      )
                      .then((res) => {
                        if (res.data.error) {
                          toast.error(res.data.error);
                        } else {
                          toast.success(res.data.message);
                          navigate("/process");
                        }
                      });
                  }
                } else {
                  formData.append("created_By", authState.name);
                  formData.append("image", values.image);
                  axios
                    .post(
                      "https://backend.seo-calgary.ca/api/v1/dashboards/addProcessList",
                      formData,
                      {
                        headers: {
                          accessToken: localStorage.getItem("authtoken"),
                        },
                      }
                    )
                    .then((res) => {
                      if (res.data.error) {
                        toast.error(res.data.error);
                      } else {
                        toast.success(res.data.message);
                        navigate("/process");
                      }
                    });
                }
              }}
            >
              {({ setFieldValue, values }) => {
                return (
                  <>
                    <Form id="basic-form">
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="title">Title :</label>
                        <Field
                          type="text"
                          name="title"
                          id="title"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="alt">Alt :</label>
                        <Field
                          type="text"
                          name="alt"
                          id="alt"
                          class="form-control"
                          required
                        />
                      </div>

                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="description">Description :</label>
                        <Field
                          type="text"
                          name="description"
                          id="description"
                          class="form-control"
                          required
                        />
                      </div>

                      <div class="form-group">
                        <label htmlFor="image">Image :</label>
                        {values.image && values.image ? (
                          <PreviewImage file={values.image} />
                        ) : listData && listData.image !== "" ? (
                          <PreviewImage
                            file={`https://backend.seo-calgary.ca/${listData.image}`}
                          />
                        ) : null}
                        <button
                          className="btn-primary"
                          type="button"
                          onClick={() => {
                            imageRef.current.click();
                          }}
                        >
                          {values.image || (listData && listData.image)
                            ? "Edit Image"
                            : "Upload Image"}
                        </button>
                        <input
                          ref={imageRef}
                          type="file"
                          id="image"
                          class="form-control"
                          onChange={(e) => {
                            setFieldValue("image", e.target.files[0]);
                          }}
                          hidden
                        />
                      </div>
                      <Field
                        type="submit"
                        value={
                          listData && listData.title !== "" ? "Update" : "Add"
                        }
                        class="btn btn-primary"
                      />
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default AddProcessList;
