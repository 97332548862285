import { authContext } from "../../Helpers/AuthContext";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { useNavigate } from "react-router";
import * as yup from "yup";
import axios from "axios";
import Logo from "../../images/brand/icon_black.svg";
import React, { useContext } from "react";

const ChangePassword = () => {
  const { authState } = useContext(authContext);
  const navigate = useNavigate();

  const initialValues = {
    oldPassword: "",
    newPassword: "",
  };

  const validationSchema = yup.object().shape({
    oldPassword: yup.string().required("please enter Your old password"),
    newPassword: yup
      .string()
      .required("please enter a new password")
      .min(8, "password-to-short enter minimun 8 characters")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "password must contain 1 uppercase, 1 lowercase,1 special character and 1 number "
      ),
    cpassword: yup
      .string()
      .required("please enter a password")
      .oneOf([yup.ref("newPassword")], "password does not match"),
  });

  const handelSubmit = async (values) => {
    await axios
      .post(
        `https://backend.seo-calgary.ca/api/v1/users/changePassword/${authState.id}`,
        values,
        {
          headers: {
            accessToken: localStorage.getItem("authtoken"),
          },
        }
      )
      .then((res) => {
        if (res.data.error) {
          window.alert(res.data.error);
        } else {
          navigate("/");
          window.alert(res.data.message);
        }
      });
  };

  return (
    <div id="wrapper">
      <div class="vertical-align-wrap">
        <div class="vertical-align-middle auth-main">
          <div class="auth-box">
            <div class="top">
              <img src={Logo} alt="Lucid" />
              <strong>Big</strong> <span>Bucket</span>
            </div>
            <div class="card">
              <div class="header">
                <p class="lead">Change Password</p>
              </div>
              <div class="body">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handelSubmit}
                >
                  <Form className="form-auth-small">
                    {/* Old password */}
                    <div class="form-group">
                      <span className="error-message">
                        <ErrorMessage
                          className="error-message"
                          name="oldPassword"
                        />
                      </span>
                      <label
                        htmlFor="oldPassword"
                        class="control-label sr-only"
                      >
                        Old Password
                      </label>
                      <Field
                        name="oldPassword"
                        type="password"
                        class="form-control"
                        id="oldPassword"
                        placeholder="Enter Your Old Password"
                      />
                    </div>
                    {/* New password */}
                    <div class="form-group">
                      <span className="error-message">
                        <ErrorMessage
                          className="error-message"
                          name="newPassword"
                        />
                      </span>
                      <label
                        htmlFor="newPassword"
                        class="control-label sr-only"
                      >
                        New Password
                      </label>
                      <Field
                        name="newPassword"
                        type="password"
                        class="form-control"
                        id="newPassword"
                        placeholder="Enter New Password"
                      />
                    </div>
                    {/* Confirm  new password */}
                    <div class="form-group">
                      <span className="error-message">
                        <ErrorMessage
                          className="error-message"
                          name="cpassword"
                        />
                      </span>
                      <label
                        htmlFor="signup-confirm-password"
                        class="control-label sr-only"
                      >
                        Confirm Password
                      </label>
                      <Field
                        name="cpassword"
                        type="password"
                        class="form-control"
                        id="signup-confirm-password"
                        placeholder="Confirm Password"
                      />
                    </div>
                    {/* submit */}
                    <Field
                      type="submit"
                      value="Change Password"
                      class="btn btn-primary btn-lg btn-block"
                    />
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
