import React, { useEffect, useState } from "react";

import axios from "axios";

//Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import TechnoSliderActionTable from "../Components/Tables/TechnoSliderActionTable";

const TechnoSlider = () => {

  const [technoSliders, setTechnoSliders] = useState([]);

  const [dataChange, setDataChange] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get("https://backend.seo-calgary.ca/api/v1/dashboards/getTechnoSliderData")
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            console.log(res.data);
            setTechnoSliders(res.data);
          }
        });
    };
    fetchData();
  }, [dataChange]);

  console.log(technoSliders);

  const handleDeleteSlide = async (id) => {
    console.log(id);
    if (window.confirm("Are you sure to Delete this Slide?")) {
      await axios
        .get(`https://backend.seo-calgary.ca/api/v1/dashboards/deleteTechnoSlide/${id}`, {
          headers: {
            accessToken: localStorage.getItem("authtoken"),
          },
        })
        .then((res) => {
          if (res.data.error) {
            toast.error(res.data.error);
          } else {
            setDataChange(!dataChange);
            toast.success(res.data.message);
          }
        });
    }
  };

  const visiblityActions = async (element) => {
    console.log(element);
    if (element.isPublished === 0) {
      if (window.confirm("Are you sure to Publish this Slide?")) {
        await axios
          .get(
            `https://backend.seo-calgary.ca/api/v1/dashboards/PublishTechnoSlide/${element.id}`,
            {
              headers: {
                accessToken: localStorage.getItem("authtoken"),
              },
            }
          )
          .then((res) => {
            if (res.data.error) {
              toast.error(res.data.error);
            } else {
              setDataChange(!dataChange);
              toast.success(res.data.message);
            }
          });
      }
    } else {
      if (window.confirm("Are you sure to Unpublish this Slide?")) {
        await axios
          .get(
            `https://backend.seo-calgary.ca/api/v1/dashboards/UnpublishTechnoSlide/${element.id}`,
            {
              headers: {
                accessToken: localStorage.getItem("authtoken"),
              },
            }
          )
          .then((res) => {
            if (res.data.error) {
              toast.error(res.data.error);
            } else {
              setDataChange(!dataChange);
              toast.success(res.data.message);
            }
          });
      }
    }
  };

  return (
    <>
      <div class="row clearfix">
        <div class="col-lg-12">
          <div class="card">
            <div class="header">
              <h2>Main Sliders</h2>
              <Link to="/add-techno-slide" className="btn btn-primary">
                Add Slide
              </Link>
            </div>
            <TechnoSliderActionTable
              data={technoSliders}
              handleDeleteSlide={handleDeleteSlide}
              visiblityActions={visiblityActions}
            />
          </div>
        </div>

        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  );
};

export default TechnoSlider;
