import React, { useState } from "react";

const PreviewImage = ({ file }) => {
  const [preview, setPreview] = useState(null);
  const reader = new FileReader();
  if (file) {
    const type = typeof file;
    if (type === "string") {
      // setPreview(file);
    } else {
      reader.readAsDataURL(file);
      reader.onload = () => {
        setPreview(reader.result);
      };
    }
  }

  return (
    <>
      <div>
        {preview ? (
          <img src={preview} alt="preview" width="200px" height="auto" />
        ) : (
          <img src={file} alt="preview" width="200px" height="auto" />
        )}
      </div>
    </>
  );
};

export default PreviewImage;
