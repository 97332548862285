import React, { useContext } from "react";
import Logo from "../../images/brand/icon_black.svg";

import { useNavigate } from "react-router";

import { Formik, Field, ErrorMessage, Form } from "formik";
import * as yup from "yup";

import axios from "axios";
import { Link } from "react-router-dom";

import { authContext } from "../../Helpers/AuthContext";

const Login = () => {
  const { setAuthState , change , setChanged } = useContext(authContext);

  const navigate = useNavigate();

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required("Email is Required")
      .email("Please enter a valid email"),
    password: yup.string().required("please enter a password"),
  });

  const handelSubmit = (values) => {
    console.log(values);
    axios
      .post("https://backend.seo-calgary.ca/api/v1/users/logInUser", values)
      .then((res) => {
        if (res.data.error) {
          window.alert(res.data.error);
          console.log(res.data.error);
        } else {
          window.alert(res.data.message);
          localStorage.setItem("authtoken", res.data.token);
          setAuthState({
            name : res.data.name,
            id: res.data.id,
            email: res.data.email,
            status: true
          })
          setChanged(!change)
          navigate("/");

        }
      });
  };

  return (
    <>
      <div id="wrapper">
        <div class="vertical-align-wrap">
          <div class="vertical-align-middle auth-main">
            <div class="auth-box">
              <div class="top">
                <img src={Logo} alt="Lucid" />
                <strong>Big</strong> <span>Bucket</span>
              </div>
              <div class="card">
                <div class="header">
                  <p class="lead">Login to your account</p>
                </div>
                <div class="body">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handelSubmit}
                  >
                    <Form className="form-auth-small">
                      {/* email */}
                      <div class="form-group">
                        <span className="error-message">
                          <ErrorMessage
                            className="error-message"
                            name="email"
                          />
                        </span>
                        <label
                          htmlFor="signup-email"
                          class="control-label sr-only"
                        >
                          Email
                        </label>
                        <Field
                          name="email"
                          type="email"
                          class="form-control"
                          id="signup-email"
                          placeholder="Your email"
                        />
                      </div>
                      {/* password */}
                      <div class="form-group">
                        <span className="error-message">
                          <ErrorMessage
                            className="error-message"
                            name="password"
                          />
                        </span>
                        <label
                          htmlFor="signup-password"
                          class="control-label sr-only"
                        >
                          Password
                        </label>
                        <Field
                          name="password"
                          type="password"
                          class="form-control"
                          id="signup-password"
                          placeholder="Enter New Password"
                        />
                      </div>
                      {/* submit */}
                      <Field
                        type="submit"
                        value="LOGIN"
                        class="btn btn-primary btn-lg btn-block"
                      />
                      <div class="bottom">
                        <span class="helper-text m-b-10">
                          <i class="fa fa-lock"></i> {/* Need to Implement */}
                          <Link to="/forgot-password">
                            Forgot password?
                          </Link>
                        </span>
                        <span>
                          Don't have an account?{" "}
                          <Link to="/register">Register</Link>
                        </span>
                      </div>
                    </Form>
                  </Formik>
                  {/* <form class="form-auth-small" action="index.html">
                                <div class="form-group">
                                    <label for="signin-email" class="control-label sr-only">Email</label>
                                    <input type="email" class="form-control" id="signin-email" value="user@domain.com" placeholder="Email"/>
                                </div>
                                <div class="form-group">
                                    <label for="signin-password" class="control-label sr-only">Password</label>
                                    <input type="password" class="form-control" id="signin-password" value="thisisthepassword" placeholder="Password"/>
                                </div>
                                <button type="submit" class="btn btn-primary btn-lg btn-block">LOGIN</button>
                                <div class="bottom">
                                    <span class="helper-text m-b-10"><i class="fa fa-lock"></i> <a href="auth-forgot-password.html">Forgot password?</a></span>
                                    <span>Don't have an account? <a href="auth-register.html">Register</a></span>
                                </div>
                            </form> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
