import React from 'react'
import Register from '../Components/UserSystems/Register'

const SignUp = () => {
  return (
    <>
    <Register />
    </>
  )
}

export default SignUp