import React, { useContext, useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";

import { authContext } from "../Helpers/AuthContext";

import axios from "axios";

//Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import SliderActionTable from "../Components/Tables/SliderActionTable";
import TestimonialActionTable from "../Components/Tables/TestimonialActionTable";

const Testimonials = () => {
  const { authState } = useContext(authContext);

  const [testimonial, setTestimonial] = useState({
    heading: "",
    description: "",
    cardData: [],
  });

  const [dataChange, setDataChange] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get("https://backend.seo-calgary.ca/api/v1/dashboards/getTestimonials")
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            console.log(res.data);
            setTestimonial(res.data.data);
          }
        });
    };
    fetchData();
  }, [dataChange]);

  const handleDeleteSlide = async (id) => {
    console.log(id);
    if (window.confirm("Are you sure to Delete this Slide?")) {
      await axios
        .get(`https://backend.seo-calgary.ca/api/v1/dashboards/deleteTestimonialSlide/${id}`, {
          headers: {
            accessToken: localStorage.getItem("authtoken"),
          },
        })
        .then((res) => {
          if (res.data.error) {
            toast.error(res.data.error);
          } else {
            setDataChange(!dataChange);
            toast.success(res.data.message);
          }
        });
    }
  };

  const visiblityActions = async (element) => {
    console.log(element);
    if (element.isPublished === 0) {
      if (window.confirm("Are you sure to Publish this Slide?")) {
        await axios
          .get(
            `https://backend.seo-calgary.ca/api/v1/dashboards/PublishTestimonialSlide/${element.id}`,
            {
              headers: {
                accessToken: localStorage.getItem("authtoken"),
              },
            }
          )
          .then((res) => {
            if (res.data.error) {
              toast.error(res.data.error);
            } else {
              setDataChange(!dataChange);
              toast.success(res.data.message);
            }
          });
      }
    } else {
      if (window.confirm("Are you sure to Unpublish this Slide?")) {
        await axios
          .get(
            `https://backend.seo-calgary.ca/api/v1/dashboards/UnpublishTestimonialSlide/${element.id}`,
            {
              headers: {
                accessToken: localStorage.getItem("authtoken"),
              },
            }
          )
          .then((res) => {
            if (res.data.error) {
              toast.error(res.data.error);
            } else {
              setDataChange(!dataChange);
              toast.success(res.data.message);
            }
          });
      }
    }
  };

  return (
    <>
      <div class="row clearfix">
        <div class="col-lg-12">
          <div class="card">
            <div class="header">
              <h2>Testimonial Heading</h2>
            </div>
            <div class="body">
              <div class="form-group col-md-6">
                <Formik
                  enableReinitialize
                  initialValues={testimonial && testimonial}
                  onSubmit={(values) => {
                    console.log("submit", values);
                    if(testimonial.heading !== "") {
                        values.updated_by = authState.name
                        axios
                        .post(
                          "https://backend.seo-calgary.ca/api/v1/dashboards/updateTestimonialInfo",
                          values,
                          {
                            headers: {
                              accessToken: localStorage.getItem("authtoken"),
                            },
                          }
                        )
                        .then((res) => {
                          if (res.data.error) {
                            toast.error(res.data.error);
                          } else {
                            toast.success(res.data.message);
                            setDataChange(!dataChange);
                          }
                        });
                    } else  {
                        values.created_by = authState.name
                        axios
                        .post(
                          "https://backend.seo-calgary.ca/api/v1/dashboards/addTestimonialsInfo",
                          values,
                          {
                            headers: {
                              accessToken: localStorage.getItem("authtoken"),
                            },
                          }
                        )
                        .then((res) => {
                          if (res.data.error) {
                            toast.error(res.data.error);
                          } else {
                            toast.success(res.data.message);
                            setDataChange(!dataChange);
                          }
                        });
                    }
                   
                  }}
                >
                  {({ setFieldValue, values }) => {
                    return (
                      <>
                        <Form id="basic-form">
                          <div class="form-group">
                            {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                            <label htmlFor="heading">Heading :</label>
                            <Field
                              type="text"
                              name="heading"
                              id="heading"
                              class="form-control"
                              required
                            />
                          </div>
                          <div class="form-group">
                            {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                            <label htmlFor="description">Description: </label>
                            <Field
                              type="text"
                              name="description"
                              id="description"
                              class="form-control"
                              required
                            />
                          </div>
                          <Field
                            type="submit"
                            value={
                              testimonial.heading !== "" ? "Update" : "Add"
                            }
                            class="btn btn-primary"
                          />
                        </Form>
                      </>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row clearfix">
        <div class="col-lg-12">
          <div class="card">
            <div class="header">
              <h2>Cards</h2>
              <Link to="/add-testimonial-card" className="btn btn-primary">
                Add Card
              </Link>
            </div>
            <TestimonialActionTable
              data={testimonial.cardData}
              handleDeleteSlide={handleDeleteSlide}
              visiblityActions={visiblityActions}
            />
          </div>
        </div>

        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  );
};

export default Testimonials;
