import React, { useContext, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router";
import { authContext } from "../../Helpers/AuthContext";

import { Formik, Field, ErrorMessage, Form } from "formik";
import * as yup from "yup";

import axios from "axios";

const AddUser = () => {
  const navigate = useNavigate();
  const { authState } = useContext(authContext);
  const { id } = useParams();
  const [user, setUser] = useState(null);

  console.log(user);

  useEffect(() => {
    const fetchUserById = async () => {
      await axios
        .get(`https://backend.seo-calgary.ca/api/v1/users/getUserById/${id}`, {
          headers: {
            accessToken: localStorage.getItem("authtoken"),
          },
        })
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
          } else {
            setUser(res.data.data);
          }
        });
    };
    fetchUserById();
  }, []);

  const initialValues = user && {
    name: user.name,
    email: user.email,
    phone: user.phone,
    updatedBy: authState.name,
    id: id,
  };

  const validationSchema = yup.object().shape({
    name: yup.string().max(40).required("Name Is Required"),
    email: yup
      .string()
      .required("Email is Required")
      .email("Please enter a valid email"),
    phone: yup.string().required("Please enter a phone number"),
    password: yup
      .string()
      .min(8, "password-to-short enter minimun 8 characters")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "password must contain 1 uppercase, 1 lowercase,1 special character and 1 number "
      ),
    cpassword: yup
      .string()
      .oneOf([yup.ref("password")], "password does not match"),
  });

  const handelSubmit = (values) => {
    console.log(values);
    axios
      .post("https://backend.seo-calgary.ca/api/v1/users/editUser", values , {
        headers: {
          accessToken: localStorage.getItem("authtoken"),
        },
      })
      .then((res) => {
        if (res.data.error) {
          console.log(res.data.error);
        } else {
          console.log(res.data.message);
          navigate("/user-management");
        }
      });
  };
  return (
    <>
      {user && (
        <div id="wrapper">
          <div class="card">
            <div class="header">
              <p class="lead">Edit User</p>
            </div>
            <div class="body">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handelSubmit}
              >
                {({ values, setFieldValue }) => {
                  return (
                    <>
                      <Form class="form-auth-small">
                        {/* name */}
                        <div class="form-group">
                          <span className="error-message">
                            <ErrorMessage name="name" />
                          </span>

                          <label htmlFor="signup-name" class="control-label">
                            Name
                          </label>
                          <Field
                            name="name"
                            type="text"
                            class="form-control"
                            id="signup-name"
                            placeholder="Full Name"
                          />
                        </div>
                        {/* email */}
                        <div class="form-group">
                          <span className="error-message">
                            <ErrorMessage
                              className="error-message"
                              name="email"
                            />
                          </span>
                          <label htmlFor="signup-email" class="control-label">
                            Email
                          </label>
                          <Field
                            name="email"
                            type="email"
                            class="form-control"
                            id="signup-email"
                            placeholder="email"
                          />
                        </div>
                        {/* phone */}
                        <div class="form-group">
                          <span className="error-message">
                            <ErrorMessage
                              className="error-message"
                              name="phone"
                            />
                          </span>
                          <label htmlFor="signup-phone" class="control-label">
                            phone
                          </label>
                          <Field
                            name="phone"
                            type="text"
                            class="form-control"
                            id="signup-phone"
                            placeholder="Phone Number"
                          />
                        </div>
                        {/* password */}
                        <div class="form-group">
                          <span className="error-message">
                            <ErrorMessage
                              className="error-message"
                              name="password"
                            />
                          </span>
                          <label
                            htmlFor="signup-password"
                            class="control-label"
                          >
                            Password : Don't fill password to
                            leave it the same.
                          </label>
                          <input
                            name="password"
                            type="password"
                            class="form-control"
                            onChange={(e) => {
                              setFieldValue("password", e.target.value);
                            }}
                            id="signup-password"
                            placeholder="Password"
                          />
                        </div>
                        {/* confirm password */}
                        <div class="form-group">
                          <span className="error-message">
                            <ErrorMessage
                              className="error-message"
                              name="cpassword"
                            />
                          </span>
                          <label
                            htmlFor="signup-confirm-password"
                            class="control-label"
                          >
                            Confirm Password :
                          </label>
                          <Field
                            name="cpassword"
                            type="password"
                            class="form-control"
                            id="signup-confirm-password"
                            required={values.password ? true : false}
                            placeholder="Confirm Password"
                          />
                        </div>
                        {/* submit */}
                        <Field
                          type="submit"
                          value="Update User"
                          class="btn btn-primary btn-lg btn-block"
                        />
                      </Form>
                    </>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddUser;
