import React from 'react'
import { Outlet } from 'react-router-dom'

const InitialNavbar = () => {
  return (
    <>
     <nav class="navbar custom-navbar navbar-expand-lg py-2">
      <div class="container-fluid px-0">
        
      </div>
    </nav>
    {/* Route Nesting Importaant to specify in the parent */}
    <Outlet />
    </>
   
  )
}

export default InitialNavbar