import React, { useContext, useEffect, useRef, useState } from "react";

import { Formik, Form, ErrorMessage, Field } from "formik";
import * as yup from "yup";
import PreviewImage from "../Helpers/PreviewImage";

import { authContext } from "../Helpers/AuthContext";

import axios from "axios";

//Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "image/svg"];

const SiteSetting = () => {
  const { authState } = useContext(authContext);

  const [siteSettingData, setSiteSettingData] = useState({
    comapny_name: "",
    logo: "",
    phone_number: "",
    email: "",
    address: "",
    city: "",
    province: "",
    zip_code: "",
    address_map: "",
  });
  const logoRef = useRef(null);
  const validationSchema = yup.object({
    logo: yup
      .mixed()
      .nullable()
      .test(
        "FILE_SIZE",
        "uploaded file too big.",
        (value) => !value || (value && value.size <= 10000000)
      )
      .test(
        "FILE_FORMAT",
        "uploaded file has unsupported format",
        (value) => !value || (value && SUPPORTED_FORMATS.includes(value?.type))
      ),
  });

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get("https://backend.seo-calgary.ca/api/v1/settings/getSettings")
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            console.log(res.data);
            setSiteSettingData(res.data.data);
            console.log(res.data.message);
          }
        });
    };
    fetchData();
  }, []);

  console.log(siteSettingData);

  return (
    <>
      {authState.status === true && (
        <div class="row clearfix">
          <div class="col-md-12">
            <div class="card">
              <div class="header">
                <h2>
                  {siteSettingData && siteSettingData.comapny_name !== ""
                    ? "Edit"
                    : "Add"}{" "}
                  Site Setting
                </h2>
              </div>
              <div class="body">
                <Formik
                  enableReinitialize
                  initialValues={
                    siteSettingData && siteSettingData.comapny_name !== ""
                      ? { ...siteSettingData, logo: "" }
                      : siteSettingData
                  }
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    console.log("submit", values);
                    const formData = new FormData();

                    formData.append("comapny_name", values.comapny_name);
                    formData.append("phone_number", values.phone_number);
                    formData.append("email", values.email);
                    formData.append("address", values.address);
                    formData.append("city", values.city);
                    formData.append("province", values.province);
                    formData.append("zip_code", values.zip_code);
                    formData.append("address_map", values.address_map);
                    formData.append("logo", values.logo);

                    if (siteSettingData.comapny_name !== "") {
                      if (siteSettingData.logo !== "" && values.logo !== "") {
                        formData.append("updated_By", authState.name);
                        console.log("with picture");
                        axios
                          .post(
                            "https://backend.seo-calgary.ca/api/v1/settings/updateSettingsWithPicture",
                            formData,
                            {
                              headers: {
                                accessToken: localStorage.getItem("authtoken"),
                              },
                            }
                          )
                          .then((res) => {
                            if (res.data.error) {
                              toast.error(res.data.error);
                            } else {
                              toast.success(res.data.message);
                            }
                          });
                      } else {
                        values.logo = siteSettingData.logo;
                        values.updated_By = authState.name;

                        console.log("without picture", values);
                        axios
                          .post(
                            "https://backend.seo-calgary.ca/api/v1/settings/updateSettingsWithoutPicture",
                            values,
                            {
                              headers: {
                                accessToken: localStorage.getItem("authtoken"),
                              },
                            }
                          )
                          .then((res) => {
                            if (res.data.error) {
                              toast.error(res.data.error);
                            } else {
                              toast.success(res.data.message);
                            }
                          });
                      }
                    } else {
                      formData.append("created_By", authState.name);
                      console.log("addition");
                      axios
                        .post(
                          "https://backend.seo-calgary.ca/api/v1/settings/addSettings",
                          formData,
                          {
                            headers: {
                              accessToken: localStorage.getItem("authtoken"),
                            },
                          }
                        )
                        .then((res) => {
                          if (res.data.error) {
                            toast.error(res.data.error);
                          } else {
                            toast.success(res.data.message);
                          }
                        });
                    }
                  }}
                >
                  {({ setFieldValue, values }) => {
                    return (
                      <>
                        <Form id="basic-form">
                          <div class="form-group">
                            {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                            <label htmlFor="comapny_name">Company Name :</label>
                            <Field
                              type="text"
                              name="comapny_name"
                              id="comapny_name"
                              class="form-control"
                              required
                            />
                          </div>
                          <div class="form-group">
                            <label htmlFor="logo">
                              Company Logo :{" "}
                              {
                                <span className="error-message">
                                  <ErrorMessage name="logo" />
                                </span>
                              }{" "}
                            </label>
                            {values.logo ? (
                              <PreviewImage file={values.logo} />
                            ) : siteSettingData.logo !== "" ? (
                              <PreviewImage
                                file={`https://backend.seo-calgary.ca/${siteSettingData.logo}`}
                              />
                            ) : null}
                            <button
                              className="btn-primary"
                              type="button"
                              onClick={() => {
                                logoRef.current.click();
                              }}
                            >
                              {values.logo || siteSettingData.logo
                                ? "Edit Image"
                                : "Upload Image"}
                            </button>
                            <input
                              ref={logoRef}
                              type="file"
                              id="logo"
                              class="form-control"
                              onChange={(e) => {
                                setFieldValue("logo", e.target.files[0]);
                              }}
                              hidden
                            />
                          </div>
                          <div class="form-group">
                            {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                            <label htmlFor="phone_number">Phone Number :</label>
                            <Field
                              type="text"
                              name="phone_number"
                              id="phone_number"
                              class="form-control"
                              required
                            />
                          </div>
                          <div class="form-group">
                            {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                            <label htmlFor="email">Email :</label>
                            <Field
                              type="email"
                              name="email"
                              id="email"
                              class="form-control"
                              required
                            />
                          </div>
                          <div class="form-group">
                            {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                            <label htmlFor="address">Address :</label>
                            <Field
                              type="text"
                              name="address"
                              id="address"
                              class="form-control"
                              required
                            />
                          </div>
                          <div class="form-group">
                            {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                            <label htmlFor="city">City :</label>
                            <Field
                              type="text"
                              name="city"
                              id="city"
                              class="form-control"
                              required
                            />
                          </div>
                          <div class="form-group">
                            {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                            <label htmlFor="province">Province :</label>
                            <Field
                              type="text"
                              name="province"
                              id="province"
                              class="form-control"
                              required
                            />
                          </div>
                          <div class="form-group">
                            {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                            <label htmlFor="zip_code">Zip Code :</label>
                            <Field
                              type="text"
                              name="zip_code"
                              id="zip_code"
                              class="form-control"
                              required
                            />
                          </div>
                          <div class="form-group">
                            {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                            <label htmlFor="address_map">
                              Address Map HTML Code :
                            </label>
                            <Field
                              type="text"
                              name="address_map"
                              id="address_map"
                              class="form-control"
                              required
                            />
                          </div>
                          <Field
                            type="submit"
                            value="Submit"
                            class="btn btn-primary"
                          />
                        </Form>
                      </>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default SiteSetting;
