import React, { useContext, useEffect, useState } from "react";

import { authContext } from "../Helpers/AuthContext";
import { Formik, Form, Field } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";

const WebPackages = () => {
  const { authState } = useContext(authContext);

  const [webPackageData, setWebPackageData] = useState({
    title: "",
    description: "",
    heading: "",
    pkg_box_heading: "",
    pkg_box_description: "",
    pkg_box_price: "",
    pkg_box_percentageOff: "",
    pkg_box_button1Phone: "",
    pkg_box_button1Link: "",
    pkg_box_button2Phone: "",
    pkg_box_button2Link: "",
    pkg_box_startProjectLink: "",
  });

  useEffect(() => {
    axios
      .get("https://backend.seo-calgary.ca/api/v1/dashboards/getWebPackages")
      .then((res) => {
        if (res.data.error) {
          toast.error(res.data.error);
        } else {
          setWebPackageData(res.data.data);
          setFeatureList(res.data.data.featureList);
        }
      });
  }, []);

  const [featureList, setFeatureList] = useState([]);
  console.log(featureList);
  const [featureValue, setFeatureValue] = useState();
  const [editingIndex, setEditingIndex] = useState(-1);
  const [deletedList] = useState([]);

  const handelAdd = () => {
    if (webPackageData.title !== "") {
      if (featureValue.trim() === "") {
        return;
      }
      if (editingIndex >= 0) {
        const newData = [...featureList];
        console.log("newData", newData);
        newData[editingIndex].feature = featureValue;
        newData[editingIndex].isChanged = true;
        setFeatureList(newData);
        setEditingIndex(-1);
      } else if (featureValue.length === 0) {
        setFeatureList([{ feature: featureValue, isNew: true }]);
      } else {
        setFeatureList([
          ...featureList,
          { feature: featureValue, isNew: true },
        ]);
      }
      setFeatureValue("");
    } else {
      if (featureValue.trim() === "") {
        return;
      }
      if (editingIndex >= 0) {
        const newData = [...featureList];
        newData[editingIndex].feature = featureValue;
        setFeatureList(newData);
        setEditingIndex(-1);
      } else if (featureValue.length === 0) {
        setFeatureList([{ feature: featureValue }]);
      } else {
        setFeatureList([...featureList, { feature: featureValue }]);
      }
      console.log(featureList);
      setFeatureValue("");
    }
  };

  const handelDelete = (index) => {
    if (webPackageData.title !== "") {
      const newData = [...featureList];
      const deletedData = newData.splice(index, 1);
      console.log("gayo",deletedData);
      setFeatureList(newData);
      deletedData[0].id && deletedList.push(deletedData[0]);
    } else {
      const newData = [...featureList];
      newData.splice(index, 1);
      setFeatureList(newData);
    }
  };

  const handelEdit = (index) => {
    if (webPackageData.title !== "") {
      setFeatureValue(featureList[index].feature);
      setEditingIndex(index);
    } else {
      setFeatureValue(featureList[index]);
      setEditingIndex(index);
    }
  };

  return (
    <div class="row clearfix">
      <div class="col-md-6">
        <div class="card">
          <div class="header">
            <h2>{webPackageData.title !== "" ? "Update" : "Add"} Content</h2>
          </div>
          <div class="body">
            <Formik
              enableReinitialize
              initialValues={webPackageData && webPackageData}
              onSubmit={(values) => {
                console.log("submit", values);
                values.featureList = featureList;
                if (featureList.length > 0) {
                  if (webPackageData.title !== "") {
                    values.deletedList = deletedList;
                    values.updated_by = authState.name;
                    axios
                      .post(
                        `https://backend.seo-calgary.ca/api/v1/dashboards/updateWebPackages`,
                        values,
                        {
                          headers: {
                            accessToken: localStorage.getItem("authtoken"),
                          },
                        }
                      )
                      .then((res) => {
                        if (res.data.error) {
                          toast.error(res.data.error);
                        } else {
                          toast.success(res.data.message);
                        }
                      });
                  } else {
                    values.created_By = authState.name;
                    values.WebPackageId = 1;
                    axios
                      .post(
                        "https://backend.seo-calgary.ca/api/v1/dashboards/addWebPackages",
                        values,
                        {
                          headers: {
                            accessToken: localStorage.getItem("authtoken"),
                          },
                        }
                      )
                      .then((res) => {
                        if (res.data.error) {
                          toast.error(res.data.error);
                        } else {
                          toast.success(res.data.message);
                        }
                      });
                  }
                } else {
                  window.alert("features required");
                }
              }}
            >
              {({ setFieldValue, values }) => {
                return (
                  <>
                    <Form id="basic-form">
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="title">Title :</label>
                        <Field
                          type="text"
                          name="title"
                          id="title"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="heading">Heading: </label>
                        <Field
                          type="text"
                          name="heading"
                          id="heading"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="description">Description: </label>
                        <Field
                          type="text"
                          name="description"
                          id="description"
                          class="form-control"
                          required
                        />
                      </div>
                      <h5>Package Box</h5>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="pkg_box_heading">Heading: </label>
                        <Field
                          type="text"
                          name="pkg_box_heading"
                          id="pkg_box_heading"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="pkg_box_description">
                          Description:
                        </label>
                        <Field
                          type="text"
                          name="pkg_box_description"
                          id="pkg_box_description"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="pkg_box_price">Price: </label>
                        <Field
                          type="text"
                          name="pkg_box_price"
                          id="pkg_box_price"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="pkg_box_percentageOff">
                          PercentageOff:{" "}
                        </label>
                        <Field
                          type="text"
                          name="pkg_box_percentageOff"
                          id="pkg_box_percentageOff"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="pkg_box_button1Phone">
                          Button1 Phone:{" "}
                        </label>
                        <Field
                          type="text"
                          name="pkg_box_button1Phone"
                          id="pkg_box_button1Phone"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="pkg_box_button1Link">
                          Button1 Link:{" "}
                        </label>
                        <Field
                          type="text"
                          name="pkg_box_button1Link"
                          id="pkg_box_button1Link"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="pkg_box_button2Phone">
                          Button2 Phone:{" "}
                        </label>
                        <Field
                          type="text"
                          name="pkg_box_button2Phone"
                          id="pkg_box_button2Phone"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="pkg_box_button2Link">
                          Button2 Link:{" "}
                        </label>
                        <Field
                          type="text"
                          name="pkg_box_button2Link"
                          id="pkg_box_button2Link"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="pkg_box_startProjectLink">
                          Start Project Link:{" "}
                        </label>
                        <Field
                          type="text"
                          name="pkg_box_startProjectLink"
                          id="pkg_box_startProjectLink"
                          class="form-control"
                          required
                        />
                      </div>

                      <Field
                        type="submit"
                        value={webPackageData.title !== "" ? "update" : "Add"}
                        class="btn btn-primary"
                      />
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div className="header">
            <h2>Features List:</h2>
          </div>
          <div className="body">
            {/* Feature List */}
            <div class="form-group">
              <input
                type="text"
                name="feature"
                value={featureValue}
                onChange={(e) => {
                  setFeatureValue(e.target.value);
                }}
                id="feature"
                class="form-control"
                required
              />
              <button onClick={handelAdd}>
                {editingIndex >= 0 ? "Save Edit" : "Save"}
              </button>
            </div>
            {featureList &&
              featureList.map(({ feature }, index) => (
                <div>
                  <p>{feature}</p>
                  <button onClick={() => handelDelete(index)}>Delete</button>
                  <button onClick={() => handelEdit(index)}>Edit</button>
                </div>
              ))}
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default WebPackages;
