import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router";

import axios from "axios";

// getSliderFormData

const SliderFormData = () => {
  const navigate = useNavigate();

  const [sliderFormData, setSliderFormData] = useState([]);
  const [change, setChange] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      axios
        .get("https://backend.seo-calgary.ca/api/v1/frontendForms/getSliderFormData")
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            setSliderFormData(res.data.data);
          }
        });
    };
    fetchData();
  }, [change]);

  const deleteMessage = (id) => {
    console.log(id);
    if (window.confirm("Delete the item?")) {
      axios
        .get(
          `https://backend.seo-calgary.ca/api/v1/frontendForms/deleteSliderFormData/${id}`,
          {
            headers: {
              accessToken: localStorage.getItem("authtoken"),
            },
          }
        )
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            window.alert(res.data.message);
            setChange(!change);
          }
        });
    }
  };

  const handleView = (id) => {
    axios
      .get(
        `https://backend.seo-calgary.ca/api/v1/frontendForms/changeViewedStatus/${id}`,
        {
          headers: {
            accessToken: localStorage.getItem("authtoken"),
          },
        }
      )
      .then((res) => {
        if (res.data.error) {
          window.alert(res.data.error);
        } else {
          navigate(`/view-slider-message/${id}`);
        }
      });
  };

  return (
    <>
      {sliderFormData && (
        <div class="body">
          <div class="table-responsive">
            <table class="table table-hover mb-0 c_list">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Viewed ?</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {sliderFormData &&
                  sliderFormData.map((element, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>
                            <span>{element.name}</span>
                          </td>
                          <td>
                            <span>{element.email}</span>
                          </td>
                          <td>
                            {element.isViewed === 0 ? (
                              <span style={{ color: "red" }}>Not Viewed</span>
                            ) : (
                              <span style={{ color: "green" }}>Viewed</span>
                            )}
                          </td>
                          <td>
                            <button
                              type="button"
                              class="btn btn-info btn-sm mr-2"
                              onClick={() => handleView(element.id)}
                              title="View"
                            >
                              <i class="fa fa-eye"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-danger btn-sm"
                              onClick={() => deleteMessage(element.id)}
                              title="Delete"
                            >
                              <i class="fa fa-trash-o"></i>
                            </button>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default SliderFormData;
