import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import axios from "axios";
import { Link } from "react-router-dom";

const ConfirmEmail = () => {
  const [confirming, setConfirming] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const startConfirming = () => {
      axios
        .get(
          `https://backend.seo-calgary.ca/api/v1/users/confirmEmail/${id}`
        )
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
          } else {
            setConfirming(false)
            console.log(res.data.message);
          }
        });
    };
    startConfirming();
  },[]);

  return (
    <div>
      <div>
        {confirming ? (
          "wait Confirming the Email"
        ) : (
          <Link to="/" className="btn-primary">
            Done Click Here To Go home
          </Link>
        )}
      </div>
    </div>
  );
};

export default ConfirmEmail;
