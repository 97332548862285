import axios from "axios";
import React, { useContext, useEffect, useState } from "react";

import { authContext } from "../Helpers/AuthContext";

import { AiOutlineCheck } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { CgUnblock } from "react-icons/cg";
import { BiBlock } from "react-icons/bi";

import { Link } from "react-router-dom";

const UserManagement = () => {
  const { authState } = useContext(authContext);
  const [usersData, setUsersData] = useState(null);
  const [change, setChange] = useState(false);

  console.log(usersData);

  useEffect(() => {
    const fetchUser = async () => {
      await axios
        .get("https://backend.seo-calgary.ca/api/v1/users/getAllUsers", {
          headers: {
            accessToken: localStorage.getItem("authtoken"),
          },
        })
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
          } else {
            setUsersData(res.data.data);
          }
        });
    };
    fetchUser();
  }, [change]);

  const handleUserDelete = async (id) => {
    console.log(id);

    if (window.confirm("Are You Sure to Delete this User?")) {
      await axios
        .post(
          "https://backend.seo-calgary.ca/api/v1/users/deleteUser",
          {
            updated_by: authState.name,
            id: id,
          },
          {
            headers: {
              accessToken: localStorage.getItem("authtoken"),
            },
          }
        )
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
          } else {
            window.alert(res.data.message);
            setChange(!change)
          }
        });
    }
  };

  const blockingActions = async (element) => {
    if (element.isBlocked === 1) {
      if (window.confirm("Are You Sure to Un Block this User?")) {
        await axios
          .post(
            "https://backend.seo-calgary.ca/api/v1/users/unblockUser",
            {
              updated_by: authState.name,
              id: element.id,
            },
            {
              headers: {
                accessToken: localStorage.getItem("authtoken"),
              },
            }
          )
          .then((res) => {
            if (res.data.error) {
              console.log(res.data.error);
            } else {
              window.alert(res.data.message);
              setChange(!change)
            }
          });
      }
    } else {
      if (window.confirm("Are You Sure to Block this User?")) {
        await axios
          .post(
            "https://backend.seo-calgary.ca/api/v1/users/blockUser",
            {
              updated_by: authState.name,
              id: element.id,
            },
            {
              headers: {
                accessToken: localStorage.getItem("authtoken"),
              },
            }
          )
          .then((res) => {
            if (res.data.error) {
              console.log(res.data.error);
            } else {
              window.alert(res.data.message);
              setChange(!change)
            }
          });
      }
    }
  };

  return (
    <div class="row clearfix">
      <div class="col-lg-12">
        <div class="card">
          <div class="header">
            <h2>User Management</h2>
            <Link to="/add-user" className="btn btn-primary">
              Add User
            </Link>
          </div>
          <div class="body">
            <div class="table-responsive">
              <table class="table table-hover mb-0 c_list">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Role</th>
                    <th>Email</th>
                    <th>Is Confirmed</th>
                    <th>Is Blocked</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {usersData &&
                    usersData.map((element, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>
                              <span>
                                {element.name}
                              </span>
                            </td>
                            <td>
                              <span>{element.role}</span>
                            </td>
                            <td>
                              <span>{element.email}</span>
                            </td>
                            <td>
                              {element.isConfirmed === 1 ? (
                                <span
                                  style={{
                                    color: "green",
                                    textAlign: "center",
                                  }}
                                >
                                  <AiOutlineCheck />
                                </span>
                              ) : (
                                <span
                                  style={{ color: "red", textAlign: "center" }}
                                >
                                  <RxCross2 />
                                </span>
                              )}
                            </td>
                            <td>
                              {element.isBlocked === 1 ? (
                                <span
                                  style={{ color: "red", textAlign: "center" }}
                                >
                                  <AiOutlineCheck />
                                </span>
                              ) : (
                                <span
                                  style={{
                                    color: "green",
                                    textAlign: "center",
                                  }}
                                >
                                  <RxCross2 />
                                </span>
                              )}
                            </td>
                            <td>
                              <Link
                                class="btn btn-info btn-sm mr-2"
                                to={`/edit-user/${element.id}`}
                                title="Edit"
                              >
                                <i class="fa fa-edit"></i>
                              </Link>
                              <button
                                type="button"
                                class="btn btn-danger btn-sm mr-2"
                                onClick={() => {
                                  handleUserDelete(element.id);
                                }}
                                title="Delete"
                              >
                                <i class="fa fa-trash-o"></i>
                              </button>
                              <button
                                type="button"
                                class={`btn  ${
                                  element.isBlocked === 1
                                    ? "btn-primary"
                                    : "btn-danger"
                                } btn-sm mr-2`}
                                onClick={() => {
                                  blockingActions(element);
                                }}
                                title={
                                  element.isBlocked === 1 ? "Unblock" : "block"
                                }
                              >
                                {element.isBlocked === 1 ? (
                                  <CgUnblock />
                                ) : (
                                  <BiBlock />
                                )}
                              </button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
