import React from "react";
import Logo from "../../images/brand/icon_black.svg";

import { useNavigate } from "react-router";

import { Formik, Field, ErrorMessage, Form } from "formik";
import * as yup from "yup";

import axios from "axios";
import { Link } from "react-router-dom";

const Register = () => {
  const navigate = useNavigate();

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    password: "",
    role: "",
  };

  const validationSchema = yup.object().shape({
    name: yup.string().max(40).required("Name Is Required"),
    email: yup
      .string()
      .required("Email is Required")
      .email("Please enter a valid email"),
    phone: yup.string().required("Please enter a phone number"),
    role: yup.string().required("Please enter a role"),
    password: yup
      .string()
      .required("please enter a password")
      .min(8, "password-to-short enter minimun 8 characters")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "password must contain 1 uppercase, 1 lowercase,1 special character and 1 number "
      ),
    cpassword: yup
      .string()
      .required("please enter a password")
      .oneOf([yup.ref("password")], "password does not match"),
  });

  const handelSubmit = (values) => {
    console.log(values);
    axios
      .post("https://backend.seo-calgary.ca/api/v1/users/registerUser", values)
      .then((res) => {
        if (res.data.error) {
          console.log(res.data.error);
        } else {
          console.log(res.data.message);
          navigate("/");
        }
      });
  };
  return (
    <>
      <div id="wrapper">
        <div class="vertical-align-wrap">
          <div class="vertical-align-middle auth-main">
            <div class="auth-box">
              <div class="top">
                <img src={Logo} alt="Lucid" />
                <strong>Big</strong> <span>Bucket</span>
              </div>
              <div class="card">
                <div class="header">
                  <p class="lead">Create an account</p>
                </div>
                <div class="body">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handelSubmit}
                  >
                    <Form class="form-auth-small">
                      {/* name */}
                      <div class="form-group">
                        <span className="error-message">
                          <ErrorMessage name="name" />
                        </span>

                        <label
                          htmlFor="signup-name"
                          class="control-label sr-only"
                        >
                          Name
                        </label>
                        <Field
                          name="name"
                          type="text"
                          class="form-control"
                          id="signup-name"
                          placeholder="Your Full Name"
                        />
                      </div>
                      {/* email */}
                      <div class="form-group">
                        <span className="error-message">
                          <ErrorMessage
                            className="error-message"
                            name="email"
                          />
                        </span>
                        <label
                          htmlFor="signup-email"
                          class="control-label sr-only"
                        >
                          Email
                        </label>
                        <Field
                          name="email"
                          type="email"
                          class="form-control"
                          id="signup-email"
                          placeholder="Your email"
                        />
                      </div>
                      {/* phone */}
                      <div class="form-group">
                        <span className="error-message">
                          <ErrorMessage
                            className="error-message"
                            name="phone"
                          />
                        </span>
                        <label
                          htmlFor="signup-phone"
                          class="control-label sr-only"
                        >
                          phone
                        </label>
                        <Field
                          name="phone"
                          type="text"
                          class="form-control"
                          id="signup-phone"
                          placeholder="Your Phone Number"
                        />
                      </div>
                      {/* Role */}
                      <div class="form-group">
                        <span className="error-message">
                          <ErrorMessage className="error-message" name="role" />
                        </span>
                        <label htmlFor="role">Role :</label>
                        <Field as="select" name="role" class="custom-select">
                          <option selected>Choose...</option>
                          <option value="User">User</option>
                          <option value="Admin">Admin</option>
                          <option value="Super Admin">Super Admin</option>
                        </Field>
                      </div>
                      {/* password */}
                      <div class="form-group">
                        <span className="error-message">
                          <ErrorMessage
                            className="error-message"
                            name="password"
                          />
                        </span>
                        <label
                          htmlFor="signup-password"
                          class="control-label sr-only"
                        >
                          Password
                        </label>
                        <Field
                          name="password"
                          type="password"
                          class="form-control"
                          id="signup-password"
                          placeholder="Enter New Password"
                        />
                      </div>
                      {/* confirm password */}
                      <div class="form-group">
                        <span className="error-message">
                          <ErrorMessage
                            className="error-message"
                            name="cpassword"
                          />
                        </span>
                        <label
                          htmlFor="signup-confirm-password"
                          class="control-label sr-only"
                        >
                          Confirm Password
                        </label>
                        <Field
                          name="cpassword"
                          type="password"
                          class="form-control"
                          id="signup-confirm-password"
                          placeholder="Confirm Password"
                        />
                      </div>
                      {/* submit */}
                      <Field
                        type="submit"
                        value="REGISTER"
                        class="btn btn-primary btn-lg btn-block"
                      />
                      <div class="bottom">
                        <span class="helper-text">
                          Already have an account? <Link to="/">Login</Link>
                        </span>
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
