import React, { useEffect, useState } from "react";

import axios from "axios";
import { Link } from "react-router-dom";

//Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { Link } from "react-router-dom";
import WhyUsActionTable from "../Components/Tables/WhyUsActionTable";

const WhyUs = () => {
  const [whyUs, setWhyUs] = useState(null);

  // const [formInfoData, setFormInfoData] = useState();

  const [dataChange, setDataChange] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get("https://backend.seo-calgary.ca/api/v1/dashboards/getwhyUsData")
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            console.log(res.data.data);
            setWhyUs(res.data.data);
          }
        });
    };
    fetchData();
  }, [dataChange]);

  const handleDeleteSlide = async (id) => {
    console.log(id);
    if (window.confirm("Are you sure to Delete this Slide?")) {
      await axios
        .get(
          `https://backend.seo-calgary.ca/api/v1/dashboards/deleteWhyUsData/${id}`,
          {
            headers: {
              accessToken: localStorage.getItem("authtoken"),
            },
          }
        )
        .then((res) => {
          if (res.data.error) {
            toast.error(res.data.error);
          } else {
            setDataChange(!dataChange);
            toast.success(res.data.message);
          }
        });
    }
  };

  const visiblityActions = async (element) => {
    console.log(element);
    if (element.isPublished === 0) {
      if (window.confirm("Are you sure to Publish this Slide?")) {
        await axios
          .get(
            `https://backend.seo-calgary.ca/api/v1/dashboards/publishWhyUsData/${element.id}`,
            {
              headers: {
                accessToken: localStorage.getItem("authtoken"),
              },
            }
          )
          .then((res) => {
            if (res.data.error) {
              toast.error(res.data.error);
            } else {
              setDataChange(!dataChange);
              toast.success(res.data.message);
            }
          });
      }
    } else {
      if (window.confirm("Are you sure to Unpublish this Slide?")) {
        await axios
          .get(
            `https://backend.seo-calgary.ca/api/v1/dashboards/unPublishWhyUsData/${element.id}`,
            {
              headers: {
                accessToken: localStorage.getItem("authtoken"),
              },
            }
          )
          .then((res) => {
            if (res.data.error) {
              toast.error(res.data.error);
            } else {
              setDataChange(!dataChange);
              toast.success(res.data.message);
            }
          });
      }
    }
  };

  return (
    <>
      <div class="row clearfix">
        <div class="col-lg-12">
          <div class="card">
            <div class="header">
              <h2>Why Us</h2>
              <Link to="/add-why-us" className="btn btn-primary">
                Add Data
              </Link>
            </div>
            <WhyUsActionTable
              data={whyUs && whyUs}
              handleDeleteSlide={handleDeleteSlide}
              visiblityActions={visiblityActions}
            />
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default WhyUs;
