import React from 'react'
import Login from '../Components/UserSystems/Login'

const SignIn = () => {
  return (
    <>
    <Login />
    </>
  )
}

export default SignIn