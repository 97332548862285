import React, { useContext, useEffect, useRef, useState } from "react";

import { authContext } from "../../Helpers/AuthContext";
import { Formik, Form, Field } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import PreviewImage from "../../Helpers/PreviewImage";

import axios from "axios";
import { useNavigate, useParams } from "react-router";

const EditSlider = () => {
  const { id } = useParams();
  const { authState } = useContext(authContext);
  const navigate = useNavigate();

  const sliderImageRef = useRef(null);

  const [slideData, setSlideData] = useState();
  const [featureList, setFeatureList] = useState([]);
  console.log(featureList);
  const [featureValue, setFeatureValue] = useState();
  const [editingIndex, setEditingIndex] = useState(-1);
  const [deletedList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`https://backend.seo-calgary.ca/api/v1/dashboards/getMainSliderDataById/${id}`)
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            console.log(res.data);
            setSlideData(res.data);
            setFeatureList( res.data.featureList);
          }
        });
    };
    fetchData();
  }, []);


  const handelAdd = () => {
    
    console.log(featureValue);
    if (featureValue.trim() === "") {
      return;
    }
    if (editingIndex >= 0) {
      const newData = [...featureList];
      console.log("newData",newData);
      newData[editingIndex].feature = featureValue;
      newData[editingIndex].isChanged = true;
      setFeatureList(newData);
      setEditingIndex(-1);
    } else if (featureValue.length === 0) {
      setFeatureList([{feature : featureValue ,  isNew: true}]);
    } else {
      setFeatureList([...featureList, {feature : featureValue , isNew: true}]);
     
    }
    setFeatureValue("");
  };

  const handelDelete = (index) => {
    const newData = [...featureList];
    const deletedData = newData.splice(index, 1);
    console.log(deletedData[0]);
    deletedList.push(deletedData[0]);
    setFeatureList(newData);
  };

  const handelEdit = (index) => {
    setFeatureValue(featureList[index].feature);
    setEditingIndex(index);
  };

  return (
    <div class="row clearfix">
      <div class="col-md-6">
        <div class="card">
          <div class="header">
            <h2>Edit Slide</h2>
          </div>
          <div class="body">
            <Formik
            enableReinitialize
              initialValues={
                  slideData ? {...slideData , sliderImage : ''} : slideData
              }
              onSubmit={(values) => {
                console.log("submit", values);
                values.featureList = featureList
                values.updated_by = authState.name
                values.deletedList = deletedList
                const formData = new FormData();

                formData.append("sliderImage", values.sliderImage);
                formData.append("values", JSON.stringify(values));

                if(slideData.sliderImage !== '' && values.sliderImage !== ''){
                  console.log('withPicture');
                  axios
                  .post(
                    "https://backend.seo-calgary.ca/api/v1/dashboards/updateMainSliderWithPicture",
                    formData,
                    {
                      headers: {
                        accessToken: localStorage.getItem("authtoken"),
                      },
                    }
                  )
                  .then((res) => {
                    if (res.data.error) {
                      toast.error(res.data.error);
                    } else {
                      toast.success(res.data.message);
                      navigate("/main-sliders");
                    }
                  });
                } else {
                  console.log('without Picture');
                  axios
                  .post(
                    "https://backend.seo-calgary.ca/api/v1/dashboards/updateMainSliderWithoutPicture",
                    values,
                    {
                      headers: {
                        accessToken: localStorage.getItem("authtoken"),
                      },
                    }
                  )
                  .then((res) => {
                    if (res.data.error) {
                      toast.error(res.data.error);
                    } else {
                      toast.success(res.data.message);
                      navigate("/main-sliders");
                    }
                  });
                }

                
              }}
            >
              {({ setFieldValue, values }) => {
                return (
                  <>
                    <Form id="basic-form">
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="heading">Heading :</label>
                        <Field
                          type="text"
                          name="heading"
                          id="heading"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="description">Description: </label>
                        <Field
                          type="text"
                          name="description"
                          id="description"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="buttonText">Button Text: </label>
                        <Field
                          type="text"
                          name="buttonText"
                          id="buttonText"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="action">Button Action :</label>
                        <Field
                          type="text"
                          name="action"
                          id="action"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        <label htmlFor="sliderImage">Slider Image :</label>
                        {values && values.sliderImage ? (
                          <PreviewImage file={values.sliderImage} />
                        ) : slideData && slideData.sliderImage !== "" ? (
                          <PreviewImage
                            file={`https://backend.seo-calgary.ca/${slideData.sliderImage}`}
                          />
                        ) : null}
                        <button
                          className="btn-primary"
                          type="button"
                          onClick={() => {
                            sliderImageRef.current.click();
                          }}
                        >
                          {(values && values.sliderImage) ||
                          (slideData && slideData.sliderImage)
                            ? "Edit Image"
                            : "Upload Image"}
                        </button>
                        <input
                          ref={sliderImageRef}
                          type="file"
                          id="sliderImage"
                          class="form-control"
                          onChange={(e) => {
                            setFieldValue("sliderImage", e.target.files[0]);
                          }}
                          hidden
                        />
                      </div>
                      <Field
                        type="submit"
                        value="Edit"
                        class="btn btn-primary"
                      />
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div className="header">
            <h2>Features List:</h2>
          </div>
          <div className="body">
            {/* Feature List */}
            <div class="form-group">
              <input
                type="text"
                name="feature"
                value={featureValue}
                onChange={(e) => {
                  setFeatureValue(e.target.value);
                }}
                id="feature"
                class="form-control"
                required
              />
              <button onClick={handelAdd}>
                {editingIndex >= 0 ? "Save Edit" : "Save"}
              </button>
            </div>
            {featureList &&
              featureList.map(({feature}, index) => (
                <div>
                  <p>{feature}</p>
                  <button onClick={() => handelDelete(index)}>Delete</button>
                  <button onClick={() => handelEdit(index)}>Edit</button>
                </div>
              ))}
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default EditSlider;
