import React, { useContext, useEffect, useState } from "react";

import { authContext } from "../../Helpers/AuthContext";
import { Formik, Form, Field } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { useNavigate, useParams } from "react-router";

const AddPackageTabListContent = () => {
  const { authState } = useContext(authContext);
  const navigate = useNavigate();

  const { tabId, contentId } = useParams();

  const [tabContentData, setTabContentData] = useState({
    title: "",
    description: "",
    price: "",
    strikedPrice: "",
    packageOfferPercent: "",
    phoneNumber: "",
    phoneLink: "",
    chatName: "",
    chatLink: "",
    buttonName: "",
    bestSellerTag: "",
  });
  console.log(tabContentData);

  useEffect(() => {
    if (contentId) {
      axios
        .get(
          `https://backend.seo-calgary.ca/api/v1/dashboards/getPackageTabListContentDataByContentId/${contentId}`
        )
        .then((res) => {
          if (res.data.error) {
            toast.error(res.data.error);
          } else {
            setTabContentData(res.data.data);
            setFeatureList(res.data.data.featureList);
          }
        });
    }
  }, [contentId]);

  const [featureList, setFeatureList] = useState([]);
  const [featureValue, setFeatureValue] = useState();
  const [editingIndex, setEditingIndex] = useState(-1);
  const [deletedList] = useState([]);

  const handelAdd = () => {
    if (contentId) {
      if (featureValue.trim() === "") {
        return;
      }
      if (editingIndex >= 0) {
        const newData = [...featureList];
        console.log("newData", newData);
        newData[editingIndex].feature = featureValue;
        newData[editingIndex].isChanged = true;
        setFeatureList(newData);
        setEditingIndex(-1);
      } else if (featureValue.length === 0) {
        setFeatureList([{ feature: featureValue, isNew: true }]);
      } else {
        setFeatureList([
          ...featureList,
          { feature: featureValue, isNew: true },
        ]);
      }
      setFeatureValue("");
    } else {
      if (featureValue.trim() === "") {
        return;
      }
      if (editingIndex >= 0) {
        const newData = [...featureList];
        newData[editingIndex].feature = featureValue;
        setFeatureList(newData);
        setEditingIndex(-1);
      } else if (featureValue.length === 0) {
        setFeatureList([{feature: featureValue}]);
      } else {
        setFeatureList([...featureList, { feature: featureValue}]);
      }
      console.log(featureList);
      setFeatureValue("");
    }
  };

  const handelDelete = (index) => {
    if (contentId) {
      const newData = [...featureList];
      const deletedData = newData.splice(index, 1);
      console.log(deletedData);
      setFeatureList(newData);
      deletedData.id && deletedList.push(deletedData[0]);
    } else {
      const newData = [...featureList];
      newData.splice(index, 1);
      setFeatureList(newData);
    }
  };

  const handelEdit = (index) => {
    if (contentId) {
      setFeatureValue(featureList[index].feature);
      setEditingIndex(index);
    } else {
      setFeatureValue(featureList[index]);
      setEditingIndex(index);
    }
  };

  return (
    <div class="row clearfix">
      <div class="col-md-6">
        <div class="card">
          <div class="header">
            <h2>{contentId ? "Update" : "Add"} Content</h2>
          </div>
          <div class="body">
            <Formik
              enableReinitialize
              initialValues={tabContentData && tabContentData}
              onSubmit={(values) => {
                console.log("submit", values);
                values.featureList = featureList;
                if (tabContentData.title !== "") {
                  values.deletedList = deletedList;
                  values.updated_by = authState.name;
                  axios
                    .post(
                      `https://backend.seo-calgary.ca/api/v1/dashboards/updatePackageTabListContentById/${contentId}`,
                      values,
                      {
                        headers: {
                          accessToken: localStorage.getItem("authtoken"),
                        },
                      }
                    )
                    .then((res) => {
                      if (res.data.error) {
                        toast.error(res.data.error);
                      } else {
                        toast.success(res.data.message);
                        navigate(
                          `/view-edit-add-package-tab-list-content/${tabId}`
                        );
                      }
                    });
                } else {
                  values.created_By = authState.name;
                  values.OurPackageTabListId = tabId;
                  axios
                    .post(
                      "https://backend.seo-calgary.ca/api/v1/dashboards/addPackageTabListContent",
                      values,
                      {
                        headers: {
                          accessToken: localStorage.getItem("authtoken"),
                        },
                      }
                    )
                    .then((res) => {
                      if (res.data.error) {
                        toast.error(res.data.error);
                      } else {
                        toast.success(res.data.message);
                        navigate(
                          `/view-edit-add-package-tab-list-content/${tabId}`
                        );
                      }
                    });
                }
              }}
            >
              {({ setFieldValue, values }) => {
                return (
                  <>
                    <Form id="basic-form">
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="title">Title :</label>
                        <Field
                          type="text"
                          name="title"
                          id="title"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="description">Description: </label>
                        <Field
                          type="text"
                          name="description"
                          id="description"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="price">price: </label>
                        <Field
                          type="text"
                          name="price"
                          id="price"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="strikedPrice">Striked Price: </label>
                        <Field
                          type="text"
                          name="strikedPrice"
                          id="strikedPrice"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="packageOfferPercent">
                          Package Offer%:{" "}
                        </label>
                        <Field
                          type="text"
                          name="packageOfferPercent"
                          id="packageOfferPercent"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="phoneNumber">
                          Phone Number for Contact:{" "}
                        </label>
                        <Field
                          type="text"
                          name="phoneNumber"
                          id="phoneNumber"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="phoneLink">Phone Link: </label>
                        <Field
                          type="text"
                          name="phoneLink"
                          id="phoneLink"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="chatName">Chat Name: </label>
                        <Field
                          type="text"
                          name="chatName"
                          id="chatName"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="chatLink">Chat Link: </label>
                        <Field
                          type="text"
                          name="chatLink"
                          id="chatLink"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="buttonName">Button Name: </label>
                        <Field
                          type="text"
                          name="buttonName"
                          id="buttonName"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="buttonLink">Button Link: </label>
                        <Field
                          type="text"
                          name="buttonLink"
                          id="buttonLink"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="bestSellerTag">
                          Best Seller: Tick for true
                        </label>
                        <Field
                          type="checkbox"
                          name="bestSellerTag"
                          id="bestSellerTag"
                          class=""                          
                        />
                      </div>

                      <Field
                        type="submit"
                        value={tabContentData.title !== "" ? "update" : "Add"}
                        class="btn btn-primary"
                      />
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div className="header">
            <h2>Features List:</h2>
          </div>
          <div className="body">
            {/* Feature List */}
            <div class="form-group">
              <input
                type="text"
                name="feature"
                value={featureValue}
                onChange={(e) => {
                  setFeatureValue(e.target.value);
                }}
                id="feature"
                class="form-control"
                required
              />
              <button onClick={handelAdd}>
                {editingIndex >= 0 ? "Save Edit" : "Save"}
              </button>
            </div>
            {featureList &&
              featureList.map(({ feature }, index) => (
                <div>
                  <p>{feature}</p>
                  <button onClick={() => handelDelete(index)}>Delete</button>
                  <button onClick={() => handelEdit(index)}>Edit</button>
                </div>
              ))}
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default AddPackageTabListContent;
