import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";

import axios from "axios";

//Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import ProcessListActionTable from "../Components/Tables/ProcessListActionTable";

const Process = () => {
  const [Process, setProcess] = useState({
    heading: "",
    listData: [],
  });

  // const [formInfoData, setFormInfoData] = useState();

  const [dataChange, setDataChange] = useState(false);
  const [sliderDataChange, setSliderDataChange] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get("https://backend.seo-calgary.ca/api/v1/dashboards/getProcess")
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            console.log(res.data.data);
            setProcess(res.data.data);
          }
        });
    };
    fetchData();
  }, [dataChange]);

  console.log(Process);

  const handleDeleteSlide = async (id) => {
    console.log(id);
    if (window.confirm("Are you sure to Delete this Slide?")) {
      await axios
        .get(
          `https://backend.seo-calgary.ca/api/v1/dashboards/deleteProcessList/${id}`,
          {
            headers: {
              accessToken: localStorage.getItem("authtoken"),
            },
          }
        )
        .then((res) => {
          if (res.data.error) {
            toast.error(res.data.error);
          } else {
            setDataChange(!dataChange);
            toast.success(res.data.message);
          }
        });
    }
  };

  const visiblityActions = async (element) => {
    console.log(element);
    if (element.isPublished === 0) {
      if (window.confirm("Are you sure to Publish this Slide?")) {
        await axios
          .get(
            `https://backend.seo-calgary.ca/api/v1/dashboards/publishProcessList/${element.id}`,
            {
              headers: {
                accessToken: localStorage.getItem("authtoken"),
              },
            }
          )
          .then((res) => {
            if (res.data.error) {
              toast.error(res.data.error);
            } else {
              setDataChange(!dataChange);
              toast.success(res.data.message);
            }
          });
      }
    } else {
      if (window.confirm("Are you sure to Unpublish this Slide?")) {
        await axios
          .get(
            `https://backend.seo-calgary.ca/api/v1/dashboards/unPublishProcessList/${element.id}`,
            {
              headers: {
                accessToken: localStorage.getItem("authtoken"),
              },
            }
          )
          .then((res) => {
            if (res.data.error) {
              toast.error(res.data.error);
            } else {
              setDataChange(!dataChange);
              toast.success(res.data.message);
            }
          });
      }
    }
  };

  return (
    <>
      <div class="row clearfix">
        <div class="col-lg-12">
          <div class="card">
            <div class="header">
              <h2>Process Heading</h2>
            </div>
            <div class="body">
              <div class="form-group col-md-6">
                <Formik
                  enableReinitialize
                  initialValues={Process && Process}
                  onSubmit={(values) => {
                    console.log("submit", values);

                    if (Process.heading !== "") {
                      axios
                        .post(
                          "https://backend.seo-calgary.ca/api/v1/dashboards/updateProcessInfo",
                          values,
                          {
                            headers: {
                              accessToken: localStorage.getItem("authtoken"),
                            },
                          }
                        )
                        .then((res) => {
                          if (res.data.error) {
                            toast.error(res.data.error);
                          } else {
                            toast.success(res.data.message);
                            setSliderDataChange(!sliderDataChange);
                          }
                        });
                    } else {
                      axios
                        .post(
                          "https://backend.seo-calgary.ca/api/v1/dashboards/addProcessInfo",
                          values,
                          {
                            headers: {
                              accessToken: localStorage.getItem("authtoken"),
                            },
                          }
                        )
                        .then((res) => {
                          if (res.data.error) {
                            toast.error(res.data.error);
                          } else {
                            toast.success(res.data.message);
                            setSliderDataChange(!sliderDataChange);
                          }
                        });
                    }
                  }}
                >
                  {({ setFieldValue, values }) => {
                    return (
                      <>
                        <Form id="basic-form">
                          <div class="form-group">
                            {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                            <label htmlFor="heading">Heading :</label>
                            <Field
                              type="text"
                              name="heading"
                              id="heading"
                              class="form-control"
                              required
                            />
                          </div>
                          <Field
                            type="submit"
                            value={Process.heading !== "" ? "Update" : "Submit"}
                            class="btn btn-primary"
                          />
                        </Form>
                      </>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row clearfix">
        <div class="col-lg-12">
          <div class="card">
            <div class="header">
              <h2>Industry Slider</h2>
              <Link to="/add-process-list" className="btn btn-primary">
                Add Slide
              </Link>
            </div>
            <ProcessListActionTable
              data={Process.listData}
              handleDeleteSlide={handleDeleteSlide}
              visiblityActions={visiblityActions}
            />
          </div>
        </div>

        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  );
};

export default Process;
