import React, { useContext, useState } from "react";

import { Formik, Form, Field } from "formik";
import { menuManagementContext } from "../../Helpers/MenuManagement";

export default function NavLinksForm({ addLinks}) {

  const { menuItems } = useContext(menuManagementContext);

  const [currentStep, setcurrentStep] = useState(0);

  const [data, setData] = useState({
    menuType: "",
    parentMenu: "",
    parentId: 0,
    name: "",
    route: "",
  });

  console.log(menuItems);
 

  const handleNextStep = (newData, isFinal = false) => {
    console.log(newData);
    setData((prev) => ({ ...prev, ...newData }));
    if (isFinal) {
      console.log("submit", newData);
      addLinks(newData);
      return;
    }
    setcurrentStep((prev) => prev + 1);
  };

  const handelPrevStep = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setcurrentStep((prev) => prev - 1);
  };

  const steps = [
    <StepOne next={handleNextStep} data={data} menuItems={menuItems} />,
    <StepTwo next={handleNextStep} prev={handelPrevStep} data={data} />,
  ];

  return (
    <>
      {steps[currentStep]}
    </>
  );
}

const StepOne = (props) => {
  const handleSubmit = (values) => {
    props.next(values);
  };

  return (
    <>
      <Formik initialValues={props.data} onSubmit={handleSubmit}>
        {({ values }) => {
          return (
            <>
              <Form id="basic-form">
                <div class="form-group">
                  <label> Menu Type:</label>
                  <br />
                  <div class="custom-control custom-radio inline-cr">
                    <Field
                      type="radio"
                      name="menuType"
                      class="custom-control-input"
                      id="parent_menu"
                      value="parent_menu"
                      required
                    />
                    <label class="custom-control-label" htmlFor="parent_menu">
                      Parent Menu
                    </label>
                  </div>
                  <div class="custom-control custom-radio inline-cr">
                    <Field
                      type="radio"
                      name="menuType"
                      class="custom-control-input"
                      id="sub_menu"
                      value="sub_menu"
                      disabled={
                        props.menuItems && props.menuItems.length > 0
                          ? false
                          : true
                      }
                    />
                    <label class="custom-control-label" htmlFor="sub_menu">
                      Sub Menu
                    </label>
                  </div>
                </div>

                {values.menuType === "sub_menu" ? (
                  <div class="form-group">
                    <label htmlFor="name">Parent Menu :</label>
                    <Field
                      as="select"
                      name="parentMenu"
                      class="custom-select"
                      id="inputGroupSelect04"
                    >
                      <option selected>Choose...</option>
                      {props.menuItems &&
                        props.menuItems.map((element, index) => {
                          return (
                            <>
                              <option
                                key={index}
                                value={element.id + "-" + element.name}
                              >
                                {element.name}
                              </option>
                            </>
                          );
                        })}
                    </Field>
                  </div>
                ) : null}
                <Field type="submit" value="Next" class="btn btn-primary" />
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

const StepTwo = (props) => {
  let parentId, name;
  if (props.data.parentMenu === "") {
    parentId = 0;
    name = '';
  } else {
    console.log(props.data.parentMenu);
    const index = props.data.parentMenu.search("-");
    console.log(index);
    parentId = parseInt(props.data.parentMenu.substring(0, index));
    name = props.data.parentMenu.substring(
      index + 1,
      props.data.parentMenu.length
    );
  }
  console.log(parentId, name);

  const handleSubmit = (values) => {
    values.parentId = parentId;
    console.log(values);
    props.next(values, true);
  };

  return (
    <>
      <Formik initialValues={props.data} onSubmit={handleSubmit}>
        {({ setFieldValue, values }) => {
          return (
            <>
              <Form id="basic-form">
                <div class="form-group">
                  {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                  <label htmlFor="name">Menu Name : {name}</label>
                  <Field
                    type="text"
                    name="name"
                    id="name"
                    class="form-control"
                    required
                  />
                </div>
                <div class="form-group">
                  {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                  <label htmlFor="route">Route Link :</label>
                  <Field
                    type="text"
                    name="route"
                    id="route"
                    class="form-control"
                    required
                  />
                </div>
                <Field
                  type="button"
                  value="Prev"
                  onClick={() => {
                    props.prev(values);
                  }}
                  class="btn btn-warning"
                />{" "}
                <Field type="submit" value="Submit" class="btn btn-primary" />
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};
