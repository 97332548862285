import React from "react";
import { Link } from "react-router-dom";
import { ChangeLinkStructure } from "../../Actions/ChangeLinkStructure";

const BreadCrumb = ({ link }) => {

  const {link_name} = ChangeLinkStructure(link)
  

  return (
    <>
      <ul class="breadcrumb">
        <li class="breadcrumb-item">
          <Link to="/">
            <i class="fa fa-home"></i>
          </Link>
        </li>
        <li class="breadcrumb-item active">{link_name}</li>
      </ul>
    </>
  );
};

export default BreadCrumb;
