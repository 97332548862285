import React, { useContext, useEffect, useState } from "react";

import { Formik, Form, Field } from "formik";

import { authContext } from "../Helpers/AuthContext";

import axios from "axios";

//Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ActionTable from "../Components/Tables/ActionTable";

const HeaderTopLinks = () => {
  const { authState } = useContext(authContext);

  const [headerTopLinksData, setHeaderTopLinksData] = useState();

  const [dataChange, setDataChange] = useState(false);

  const initialValues = {
    route: "",
    name: "",
    icon: "",
  };

  const deleteLink = async (id) => {
    console.log(id);
    if (window.confirm("Delete the item?")) {
      await axios
        .get(
          `https://backend.seo-calgary.ca/api/v1/dashboards/deleteHeaderLinkById/${id}`,
          {
            headers: {
              accessToken: localStorage.getItem("authtoken"),
            },
          }
        )
        .then((res) => {
          if (res.data.error) {
            toast.error(res.data.error);
          } else {
            setDataChange(!dataChange);
            toast.success(res.data.message);
          }
        });
    }
  };

  const editData = async (element) => {
    element.updated_By = authState && authState.name;
    await axios
      .post(
        `https://backend.seo-calgary.ca/api/v1/dashboards/updateHeaderLinkById/${element.id}`,
        element,
        {
          headers: {
            accessToken: localStorage.getItem("authtoken"),
          },
        }
      )
      .then((res) => {
        if (res.data.error) {
          toast.error(res.data.error);
        } else {
          setDataChange(!dataChange);
          toast.success(res.data.message);
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get("https://backend.seo-calgary.ca/api/v1/dashboards/getHeaderLinks")
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            console.log(res.data);
            setHeaderTopLinksData(res.data.data);
            console.log(res.data.message);
          }
        });
    };
    fetchData();
  }, [dataChange]);

  console.log(headerTopLinksData);

  return (
    <>
      <div class="row clearfix">
        <div class="col-lg-12">
          <div class="card">
            <div class="header">
              <h2>Header Top Links</h2>
            </div>
            <ActionTable
              data={headerTopLinksData}
              editData={editData}
              deleteLink={deleteLink}
            />
          </div>
        </div>
        {authState.status === true && (
          <div class="row clearfix">
            <div class="col-md-6">
              <div class="card">
                <div class="header">
                  <h2>Add Link</h2>
                </div>
                <div class="body">
                  <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => {
                      console.log("submit", values);
                      values.created_By = authState && authState.name;

                      axios
                        .post(
                          "https://backend.seo-calgary.ca/api/v1/dashboards/addHeaderLinks",
                          values,
                          {
                            headers: {
                              accessToken: localStorage.getItem("authtoken"),
                            },
                          }
                        )
                        .then((res) => {
                          if (res.data.error) {
                            toast.error(res.data.error);
                          } else {
                            setDataChange(!dataChange);
                            toast.success(res.data.message);
                          }
                        });
                    }}
                  >
                    {({ setFieldValue, values }) => {
                      return (
                        <>
                          <Form id="basic-form">
                            <div class="form-group">
                              {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                              <label htmlFor="name">Route Name :</label>
                              <Field
                                type="text"
                                name="name"
                                id="name"
                                class="form-control"
                                required
                              />
                            </div>
                            <div class="form-group">
                              {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                              <label htmlFor="route">Route Link :</label>
                              <Field
                                type="text"
                                name="route"
                                id="route"
                                class="form-control"
                                required
                              />
                            </div>
                            <div class="form-group">
                              {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                              <label htmlFor="icon">
                                Icon class(font-awesome) :
                              </label>
                              <Field
                                type="text"
                                name="icon"
                                id="icon"
                                class="form-control"
                                required
                              />
                            </div>
                            <Field
                              type="submit"
                              value="Submit"
                              class="btn btn-primary"
                            />
                          </Form>
                        </>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        )}
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  );
};

export default HeaderTopLinks;
