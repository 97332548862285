import React, { useContext } from "react";
import { authContext } from "../Helpers/AuthContext";

const DashboardHome = () => {
  const { authState } = useContext(authContext);
  return (
    <>
      {authState.status && authState.name}
    </>
  );
};

export default DashboardHome;
