import React, { useContext, useRef, useState } from "react";

import { authContext } from "../../Helpers/AuthContext";
import { Formik, Form, Field } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import PreviewImage from "../../Helpers/PreviewImage";

import axios from "axios";
import { useNavigate } from "react-router";

const AddMainSlide = () => {
  const { authState } = useContext(authContext);
  const navigate = useNavigate()

  const sliderImageRef = useRef(null);

  let slideData = null;

  const [featureList, setFeatureList] = useState([]);
  const [featureValue, setFeatureValue] = useState();
  const [editingIndex, setEditingIndex] = useState(-1);

  const initialValues = {
    heading: "",
    description: "",
    sliderImage: "",
    buttonText: "",
    action: "",
  };

  const handelAdd = () => {
    if (featureValue.trim() === "") {
      return;
    }
    if (editingIndex >= 0) {
      const newData = [...featureList];
      newData[editingIndex] = featureValue;
      setFeatureList(newData);
      setEditingIndex(-1);
    } else if (featureValue.length === 0) {
      setFeatureList([featureValue]);
    } else {
      setFeatureList([...featureList, featureValue]);
    }
    setFeatureValue("");
  };

  const handelDelete = (index) => {
    const newData = [...featureList];
    newData.splice(index, 1);
    setFeatureList(newData);
  };

  const handelEdit = (index) => {
    setFeatureValue(featureList[index]);
    setEditingIndex(index);
  };

  return (
    <div class="row clearfix">
      <div class="col-md-6">
        <div class="card">
          <div class="header">
            <h2>Add Slide</h2>
          </div>
          <div class="body">
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                console.log("submit", values);

                const formData = new FormData();

                formData.append("heading" , values.heading);
                formData.append("description" , values.description);
                formData.append("buttonText" , values.buttonText);
                formData.append("action" , values.action);
                formData.append("created_By" , authState.name);
                formData.append("sliderImage" , values.sliderImage);
                formData.append('featureList' , JSON.stringify(featureList));

                axios
                  .post(
                    "https://backend.seo-calgary.ca/api/v1/dashboards/addMainSlider",
                    formData,
                    {
                      headers: {
                        accessToken: localStorage.getItem("authtoken"),
                      },
                    }
                  )
                  .then((res) => {
                    if (res.data.error) {
                      toast.error(res.data.error);
                    } else {
                      toast.success(res.data.message);
                      navigate('/main-sliders');
                    }
                  });
              }}
            >
              {({ setFieldValue, values }) => {
                return (
                  <>
                    <Form id="basic-form">
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="heading">Heading :</label>
                        <Field
                          type="text"
                          name="heading"
                          id="heading"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="description">Description: </label>
                        <Field
                          type="text"
                          name="description"
                          id="description"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="buttonText">Button Text: </label>
                        <Field
                          type="text"
                          name="buttonText"
                          id="buttonText"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="action">Button Action :</label>
                        <Field
                          type="text"
                          name="action"
                          id="action"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        <label htmlFor="sliderImage">Slider Image :</label>
                        {values.sliderImage && values.sliderImage ? (
                          <PreviewImage file={values.sliderImage} />
                        ) : slideData && slideData.sliderImage !== "" ? (
                          <PreviewImage
                            file={`https://backend.seo-calgary.ca/${slideData.sliderImage}`}
                          />
                        ) : null}
                        <button
                          className="btn-primary"
                          type="button"
                          onClick={() => {
                            sliderImageRef.current.click();
                          }}
                        >
                          {values.sliderImage ||
                          (slideData && slideData.sliderImage)
                            ? "Edit Image"
                            : "Upload Image"}
                        </button>
                        <input
                          ref={sliderImageRef}
                          type="file"
                          id="sliderImage"
                          class="form-control"
                          onChange={(e) => {
                            setFieldValue("sliderImage", e.target.files[0]);
                          }}
                          hidden
                        />
                      </div>
                      <Field
                        type="submit"
                        value="Submit"
                        class="btn btn-primary"
                      />
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div className="header">
            <h2>Features List:</h2>
          </div>
          <div className="body">
            {/* Feature List */}
            <div class="form-group">
              <input
                type="text"
                name="feature"
                value={featureValue}
                onChange={(e) => {
                  setFeatureValue(e.target.value);
                }}
                id="feature"
                class="form-control"
                required
              />
              <button onClick={handelAdd}>
                {editingIndex >= 0 ? "Save Edit" : "Save"}
              </button>
            </div>
            {featureList &&
              featureList.map((data, index) => (
                <div>
                  <p>{data}</p>
                  <button onClick={() => handelDelete(index)}>Delete</button>
                  <button onClick={() => handelEdit(index)}>Edit</button>
                </div>
              ))}
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default AddMainSlide;
