import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const OurWorkActionTable = ({
  data,
  activeDeactive,
  anyTabListActive,
  handleDeleteSlide,
  visiblityActions,
}) => {
  return (
    <>
      {data && data.length > 0 ? (
        <div class="body">
          <div class="table-responsive">
            <table class="table table-hover mb-0 c_list">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>IsActive ?</th>
                  <th>Published ?</th>
                  <th>View edit and add Content</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((element, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>
                            <span>{element.name}</span>
                          </td>
                          <td>
                            <span className="">
                              {element.isActive === 1 ? (
                                <>
                                  <span>Active</span>
                                  <button
                                    onClick={() => {
                                      activeDeactive(element);
                                    }}
                                    className="ml-2 btn btn-danger"
                                  >
                                    Deactivate
                                  </button>
                                </>
                              ) : null}
                              {anyTabListActive === false ? (
                                <>
                                  <button
                                    onClick={() => {
                                      activeDeactive(element);
                                    }}
                                    className="ml-2 btn btn-warning"
                                  >
                                    Activate
                                  </button>
                                </>
                              ) : null}
                            </span>
                          </td>
                          <td>
                            {element.isPublished === 0 ? (
                              <span style={{ color: "red" }}>
                                Not Published
                              </span>
                            ) : (
                              <span style={{ color: "green" }}>Published</span>
                            )}
                          </td>

                          <td>
                            <Link
                              to={`/view-edit-add-tab-list-content/${element.id}`}
                              class="btn btn-primary btn-sm mr-2"
                              title="View"
                            >
                              <i class="fa fa-eye"></i>
                            </Link>
                          </td>
                          <td>
                            <button
                              type="button"
                              class={`btn ${
                                element.isPublished === 0
                                  ? "btn-primary"
                                  : "btn-warning"
                              } btn-sm mr-2`}
                              onClick={() => {
                                visiblityActions(element);
                              }}
                              title={
                                element.isPublished === 0
                                  ? "Publish"
                                  : "Unpublish"
                              }
                            >
                              {element.isPublished === 0 ? (
                                <i class="fa fa-eye"></i>
                              ) : (
                                <i class="fa fa-eye-slash"></i>
                              )}
                            </button>
                            <Link
                              to={`/view-edit-tab_list/${element.id}`}
                              class="btn btn-info btn-sm mr-2"
                              title="View & Edit"
                            >
                              <i class="fa fa-edit"></i>
                            </Link>
                            <button
                              type="button"
                              class="btn btn-danger btn-sm mr-2"
                              onClick={() => {
                                handleDeleteSlide(element.id);
                              }}
                              title="Delete"
                            >
                              <i class="fa fa-trash-o"></i>
                            </button>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <h4>No data Found</h4>
      )}
    </>
  );
};

export default OurWorkActionTable;
