import axios from "axios";
import { useContext, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { menuManagementContext } from "../../Helpers/MenuManagement";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { Formik, Form, Field } from "formik";

const Menu = ({
  handlePublish,
  handleUnpublish,
  handleEdit,
  handleDelete,
}) => {
  const { menuItems , setMenuItems } = useContext(menuManagementContext);

  const [show, setShow] = useState(false);
  const [values, setValues] = useState({});

  const [editableData, setEditableData] = useState();

  const handleClose = () => setShow(false);
  const handleShow = (element) => {
    setEditableData(element);
    setShow(true);
  };

  const handelSubmit = () => {
    console.log(values);
    handleEdit(values);
    handleClose();
  };

  const handleDragEnd = (result) => {
    const { destination, source, type } = result;

    // If dropped outside a droppable
    if (!destination) {
      return;
    }

    // If dropped in the same position
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const newMenuItems = Array.from(menuItems);

    if (type === "parent") {
      const movedItem = newMenuItems.splice(source.index, 1)[0];
      newMenuItems.splice(destination.index, 0, movedItem);
      setMenuItems(newMenuItems);
    } else if (type === "child") {
      console.log("child");
      const parentIndex = menuItems.findIndex(
        (item) => item.name === source.droppableId
      );
      const parentItem = menuItems[parentIndex];
      const newChildren = Array.from(parentItem.hasChildren);
      const movedChild = newChildren.splice(source.index, 1)[0];
      newChildren.splice(destination.index, 0, movedChild);
      const newParentItem = {
        ...parentItem,
        hasChildren: newChildren,
      };

      newMenuItems.splice(parentIndex, 1, newParentItem);
      setMenuItems(newMenuItems);
      console.log(newMenuItems);
    }

    axios
      .post(
        `https://backend.seo-calgary.ca/api/v1/dashboards/updatePosition`,
        {
          items: newMenuItems.map((item, index) => ({
            id: item.id,
            position: index,
            childPositions:
              item.hasChildren &&
              item.hasChildren.map((child, i) => ({
                id: child.id,
                position: i,
              })),
          })),
        },
        {
          headers: {
            accessToken: localStorage.getItem("authtoken"),
          },
        }
      )
      .then((res) => {
        if (res.data.error) {
          console.log(res.data.error);
        } else {
          console.log(res.data.message);
        }
      });
  };

  return (
    <>
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="menu" type="parent">
        {(provided) => {
          return (
            <ul
              className="menu-manager"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {menuItems &&
                menuItems.map((item, index) => (
                  <Draggable
                    key={index}
                    draggableId={item.id.toString()}
                    index={index}
                    type="parent"
                  >
                    {(provided) => (
                      <>
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className="flexType">
                            <div>
                              {item.name} --
                              {item.isPublished === 0 ? (
                                <span style={{ color: "red" }}>
                                  Not Published
                                </span>
                              ) : (
                                <span style={{ color: "green" }}>
                                  Published
                                </span>
                              )}
                            </div>
                            <div>
                              {item.isPublished === 0 ? (
                                <button
                                  type="button"
                                  class="btn btn-primary btn-sm"
                                  onClick={() => {
                                    handlePublish(item.id);
                                  }}
                                  title="Publish"
                                >
                                  Publish
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  class="btn btn-warning btn-sm"
                                  onClick={() => {
                                    handleUnpublish(item.id);
                                  }}
                                  title="Unpublish"
                                >
                                  Unpublish
                                </button>
                              )}
                              &nbsp;
                              <button
                                type="button"
                                class="btn btn-info btn-sm"
                                onClick={() => {
                                  handleShow(item);
                                }}
                                title="Edit"
                              >
                                <i class="fa fa-edit"></i>
                              </button>
                              &nbsp;
                              <button
                                type="button"
                                class="btn btn-danger btn-sm"
                                onClick={() => {
                                  handleDelete(item.id);
                                }}
                                title="Delete"
                              >
                                <i class="fa fa-trash-o"></i>
                              </button>
                            </div>
                          </div>

                          <Droppable droppableId={item.name} type="child">
                            {(provided) => {
                              return (
                                <ul
                                  className="gg"
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {item.hasChildren.length > 0 &&
                                    item.hasChildren.map((child, i) => (
                                      <Draggable
                                        key={i}
                                        draggableId={child.id.toString()}
                                        index={i}
                                        type="child"
                                      >
                                        {(provided) => (
                                          <li
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <div className="flexType">
                                              <div>
                                                {child.name} --
                                                {child.isPublished === 0 ? (
                                                  <span
                                                    style={{ color: "red" }}
                                                  >
                                                    Not Published
                                                  </span>
                                                ) : (
                                                  <span
                                                    style={{ color: "green" }}
                                                  >
                                                    Published
                                                  </span>
                                                )}
                                              </div>
                                              <div>
                                                {child.isPublished === 0 ? (
                                                  <button
                                                    type="button"
                                                    class="btn btn-primary btn-sm"
                                                    onClick={() => {
                                                      handlePublish(child.id);
                                                    }}
                                                    title="Publish"
                                                  >
                                                    Publish
                                                  </button>
                                                ) : (
                                                  <button
                                                    type="button"
                                                    class="btn btn-warning btn-sm"
                                                    onClick={() => {
                                                      handleUnpublish(child.id);
                                                    }}
                                                    title="Unpublish"
                                                  >
                                                    Unpublish
                                                  </button>
                                                )}
                                                &nbsp;
                                                <button
                                                  type="button"
                                                  class="btn btn-info btn-sm"
                                                  onClick={() => {
                                                    handleShow(child);
                                                  }}
                                                  title="Edit"
                                                >
                                                  <i class="fa fa-edit"></i>
                                                </button>
                                                &nbsp;
                                                <button
                                                  type="button"
                                                  class="btn btn-danger btn-sm"
                                                  onClick={() => {
                                                    handleDelete(child.id);
                                                  }}
                                                  title="Delete"
                                                >
                                                  <i class="fa fa-trash-o"></i>
                                                </button>
                                              </div>
                                            </div>
                                          </li>
                                        )}
                                      </Draggable>
                                    ))}
                                  {provided.placeholder}
                                </ul>
                              );
                            }}
                          </Droppable>
                        </li>
                      </>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </ul>
          );
        }}
      </Droppable>
    </DragDropContext>
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div class="row clearfix">
            <div class="col-md-6">
              <div class="card">
                <div class="header">
                  <h2>Add Link</h2>
                </div>
                <div class="body">
                  <Formik
                    innerRef={(formikActions) => (formikActions? setValues(formikActions.values) : setValues({}))}
                    initialValues={editableData && editableData}
                  >
                        <Form id="basic-form">
                          <div class="form-group">
                            {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                            <label htmlFor="name">Name :</label>
                            <Field
                              type="text"
                              name="name"
                              id="name"
                              class="form-control"
                              required
                            />
                          </div>
                          <div class="form-group">
                            {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                            <label htmlFor="route">Route:</label>
                            <Field
                              type="text"
                              name="route"
                              id="route"
                              class="form-control"
                              required
                            />
                          </div>                         
                        </Form>
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handelSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Menu;
