import React, { useContext, useEffect, useRef, useState } from "react";

import { authContext } from "../Helpers/AuthContext";
import { Formik, Form, Field } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import PreviewImage from "../Helpers/PreviewImage";

import axios from "axios";
import { useNavigate } from "react-router";

const OurServices = () => {
  const { authState } = useContext(authContext);
  const navigate = useNavigate();

  const backgroundImageRef = useRef(null);
  const featuredImageRef = useRef(null);

  const [ourServiceData, setOurServiceData] = useState({
    heading: "",
    button1Name: "",
    button1Link: "",
    button2Name: "",
    button2Link: "",
    backgroundImage: "",
    featuredImage: "",
    featuredImageAlt: "",
  });
  const [featureList, setFeatureList] = useState([]);
  const [serviceName, setServiceName] = useState();
  const [serviceLink, setServiceLink] = useState();
  const [editingIndex, setEditingIndex] = useState(-1);
  const [deletedList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get("https://backend.seo-calgary.ca/api/v1/dashboards/getService")
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            console.log(res.data);
            setOurServiceData(res.data);
            setFeatureList(res.data.services);
          }
        });
    };

    fetchData();
  }, []);

  const handelAdd = () => {
    if (serviceName.trim() === "" && serviceLink.trim() === "") {
      return;
    }
    if (editingIndex >= 0) {
      const newData = [...featureList];
      newData[editingIndex].name = serviceName;
      newData[editingIndex].link = serviceLink;
      newData[editingIndex].isChanged = true;
      setFeatureList(newData);
      setEditingIndex(-1);
    } else if (featureList.length === 0) {
      setFeatureList([{ name: serviceName, link: serviceLink, isNew: true }]);
    } else {
      setFeatureList([
        ...featureList,
        { name: serviceName, link: serviceLink, isNew: true },
      ]);
    }
    setServiceName("");
    setServiceLink("");
  };

  const handelDelete = (index) => {
    const newData = [...featureList];
    const deletedData = newData.splice(index, 1);
    newData.splice(index, 1);
    deletedList.push(deletedData[0]);
    setFeatureList(newData);
  };

  const handelEdit = (index) => {
    setServiceName(featureList[index].name);
    setServiceLink(featureList[index].link);
    setEditingIndex(index);
  };

  return (
    <div class="row clearfix">
      <div class="col-md-6">
        <div class="card">
          <div class="header">
            <h2>{ourServiceData.heading !== "" ? "Edit" : "Add"} Services</h2>
          </div>
          <div class="body">
            <Formik
              enableReinitialize
              initialValues={
                ourServiceData
                  ? {
                      ...ourServiceData,
                      backgroundImage: "",
                      featuredImage: "",
                    }
                  : ourServiceData
              }
              onSubmit={(values) => {
                console.log("submit", values);
                values.deletedList = deletedList;
                const formData = new FormData();
                formData.append("heading", values.heading);
                formData.append("button1Name", values.button1Name);
                formData.append("button1Link", values.button1Link);
                formData.append("button2Name", values.button2Name);
                formData.append("button2Link", values.button2Link);
                formData.append("featuredImageAlt", values.featuredImageAlt);
                formData.append("deletedList", JSON.stringify(deletedList));
                formData.append("services", JSON.stringify(featureList));

                if (ourServiceData.heading !== "") {
                  console.log("Edition");
                  if (
                    values.backgroundImage !== "" &&
                    values.featuredImage === ""
                  ) {
                    formData.append("Image", values.backgroundImage);
                    formData.append("updated_by", authState.name);
                    axios
                      .post(
                        "https://backend.seo-calgary.ca/api/v1/dashboards/updateBackgroundImageService",
                        formData,
                        {
                          headers: {
                            accessToken: localStorage.getItem("authtoken"),
                          },
                        }
                      )
                      .then((res) => {
                        if (res.data.error) {
                          toast.error(res.data.error);
                        } else {
                          toast.success(res.data.message);
                        }
                      });
                  } else if (
                    values.backgroundImage === "" &&
                    values.featuredImage !== ""
                  ) {
                    formData.append("Image", values.featuredImage);
                    formData.append("updated_by", authState.name);
                    axios
                      .post(
                        "https://backend.seo-calgary.ca/api/v1/dashboards/updateFeaturedImageService",
                        formData,
                        {
                          headers: {
                            accessToken: localStorage.getItem("authtoken"),
                          },
                        }
                      )
                      .then((res) => {
                        if (res.data.error) {
                          toast.error(res.data.error);
                        } else {
                          toast.success(res.data.message);
                        }
                      });
                  } else if (
                    values.backgroundImage !== "" &&
                    values.featuredImage !== ""
                  ) {
                    formData.append("Images[]", values.backgroundImage);
                    formData.append("Images[]", values.featuredImage);
                    formData.append("updated_by", authState.name);
                    axios
                      .post(
                        "https://backend.seo-calgary.ca/api/v1/dashboards/updateImagesService",
                        formData,
                        {
                          headers: {
                            accessToken: localStorage.getItem("authtoken"),
                          },
                        }
                      )
                      .then((res) => {
                        if (res.data.error) {
                          toast.error(res.data.error);
                        } else {
                          toast.success(res.data.message);
                        }
                      });
                  } else {
                    values.services = featureList;
                    values.updated_by = authState.name;
                    axios
                      .post(
                        "https://backend.seo-calgary.ca/api/v1/dashboards/updateService",
                        values,
                        {
                          headers: {
                            accessToken: localStorage.getItem("authtoken"),
                          },
                        }
                      )
                      .then((res) => {
                        if (res.data.error) {
                          toast.error(res.data.error);
                        } else {
                          toast.success(res.data.message);
                        }
                      });
                  }
                } else {
                  console.log("Addition");
                  formData.append("Images[]", values.backgroundImage);
                  formData.append("Images[]", values.featuredImage);
                  formData.append("created_by", authState.name);
                  axios
                    .post(
                      "https://backend.seo-calgary.ca/api/v1/dashboards/addService",
                      formData,
                      {
                        headers: {
                          accessToken: localStorage.getItem("authtoken"),
                        },
                      }
                    )
                    .then((res) => {
                      if (res.data.error) {
                        toast.error(res.data.error);
                      } else {
                        toast.success(res.data.message);
                      }
                    });
                }
              }}
            >
              {({ setFieldValue, values }) => {
                return (
                  <>
                    <Form id="basic-form">
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="heading">Heading :</label>
                        <Field
                          type="text"
                          name="heading"
                          id="heading"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="button1Name">Button1 Name: </label>
                        <Field
                          type="text"
                          name="button1Name"
                          id="button1Name"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="button1Link">Button1 Link: </label>
                        <Field
                          type="text"
                          name="button1Link"
                          id="button1Link"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="button2Name">Button2 Name: </label>
                        <Field
                          type="text"
                          name="button2Name"
                          id="button2Name"
                          class="form-control"
                          required
                        />
                      </div>
                      
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="button2Link">Button2 Link: </label>
                        <Field
                          type="text"
                          name="button2Link"
                          id="button2Link"
                          class="form-control"
                          required
                        />
                      </div>

                      <div class="form-group">
                        <label htmlFor="backgroundImage">
                          Background Image :
                        </label>
                        {values.backgroundImage && values.backgroundImage ? (
                          <PreviewImage file={values.backgroundImage} />
                        ) : ourServiceData &&
                          ourServiceData.backgroundImage !== "" ? (
                          <PreviewImage
                            file={`https://backend.seo-calgary.ca/${ourServiceData.backgroundImage}`}
                          />
                        ) : null}
                        <button
                          className="btn-primary"
                          type="button"
                          onClick={() => {
                            backgroundImageRef.current.click();
                          }}
                        >
                          {values.backgroundImage ||
                          (ourServiceData && ourServiceData.backgroundImage)
                            ? "Edit Image"
                            : "Upload Image"}
                        </button>
                        <input
                          ref={backgroundImageRef}
                          type="file"
                          id="backgroundImage"
                          class="form-control"
                          onChange={(e) => {
                            setFieldValue("backgroundImage", e.target.files[0]);
                          }}
                          hidden
                        />
                      </div>
                      <div class="form-group">
                        <label htmlFor="featuredImage">Featured Image :</label>
                        {values.featuredImage && values.featuredImage ? (
                          <PreviewImage file={values.featuredImage} />
                        ) : ourServiceData &&
                          ourServiceData.featuredImage !== "" ? (
                          <PreviewImage
                            file={`https://backend.seo-calgary.ca/${ourServiceData.featuredImage}`}
                          />
                        ) : null}
                        <button
                          className="btn-primary"
                          type="button"
                          onClick={() => {
                            featuredImageRef.current.click();
                          }}
                        >
                          {values.featuredImage ||
                          (ourServiceData && ourServiceData.featuredImage)
                            ? "Edit Image"
                            : "Upload Image"}
                        </button>
                        <input
                          ref={featuredImageRef}
                          type="file"
                          id="featuredImage"
                          class="form-control"
                          onChange={(e) => {
                            setFieldValue("featuredImage", e.target.files[0]);
                          }}
                          hidden
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="featuredImageAlt">Alt Tag for Featured Image: </label>
                        <Field
                          type="text"
                          name="featuredImageAlt"
                          id="featuredImageAlt"
                          class="form-control"
                          required
                        />
                      </div>
                      <Field
                        type="submit"
                        value="Submit"
                        class="btn btn-primary"
                      />
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div className="header">
            <h2>Service List:</h2>
          </div>
          <div className="body">
            {/* Feature List */}
            <div class="form-group">
              <label htmlFor="name"> Name </label>
              <input
                type="text"
                name="name"
                value={serviceName}
                onChange={(e) => {
                  setServiceName(e.target.value);
                }}
                id="name"
                class="form-control"
                required
              />
              <label htmlFor="link"> Link </label>
              <input
                type="text"
                name="link"
                value={serviceLink}
                onChange={(e) => {
                  setServiceLink(e.target.value);
                }}
                id="link"
                class="form-control"
                required
              />
              <button onClick={handelAdd}>
                {editingIndex >= 0 ? "Save Edit" : "Save"}
              </button>
            </div>
            {featureList &&
              featureList.map(({ name, link }, index) => (
                <div>
                  <p>Name:{name}</p>
                  <p>Link:{link}</p>
                  <button onClick={() => handelDelete(index)}>Delete</button>
                  <button onClick={() => handelEdit(index)}>Edit</button>
                </div>
              ))}
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default OurServices;
