import React, { useEffect, useState } from "react";
import Logo from "../../images/brand/icon_black.svg";

import { useNavigate, useParams } from "react-router";

import { Formik, Field, ErrorMessage, Form } from "formik";
import * as yup from "yup";

import axios from "axios";

const ResetPassword = () => {
  const { id, token } = useParams();
  const navigate = useNavigate();

  const [email, setEmail] = useState(null);

  useEffect(() => {
    axios
      .get(`https://backend.seo-calgary.ca/api/v1/users/resetPassword/${id}/${token}`)
      .then((res) => {
        if (res.data.error) {
          window.alert(res.data.error);
        } else {
          setEmail(res.data.email);
        }
      });
  }, []);

  const initialValues = {
    password: "",
  };

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required("please enter a password")
      .min(8, "password-to-short enter minimun 8 characters")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "password must contain 1 uppercase, 1 lowercase,1 special character and 1 number "
      ),
    cpassword: yup
      .string()
      .required("please enter a password")
      .oneOf([yup.ref("password")], "password does not match"),
  });

  const handelSubmit = (values) => {
    console.log(values);
    axios
      .post(`https://backend.seo-calgary.ca/api/v1/users/resetPassword/${id}/${token}`, values)
      .then((res) => {
        if (res.data.error) {
          window.alert(res.data.error);
          console.log(res.data.error);
        } else {
          window.alert(res.data.message);
          navigate("/");
        }
      });
  };
  return (
    <>
      {email ? (
        <div id="wrapper">
          <div class="vertical-align-wrap">
            <div class="vertical-align-middle auth-main">
              <div class="auth-box">
                <div class="top">
                  <img src={Logo} alt="Lucid" />
                  <strong>Big</strong> <span>Bucket</span>
                </div>
                <div class="card">
                  <div class="header">
                    <p class="lead">Enter Your Email Address</p>
                  </div>
                  <div class="body">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handelSubmit}
                    >
                      <Form className="form-auth-small">
                        {/* password */}
                        <div class="form-group">
                          <span className="error-message">
                            <ErrorMessage
                              className="error-message"
                              name="password"
                            />
                          </span>
                          <label
                            htmlFor="signup-password"
                            class="control-label sr-only"
                          >
                            Password
                          </label>
                          <Field
                            name="password"
                            type="password"
                            class="form-control"
                            id="signup-password"
                            placeholder="Enter New Password"
                          />
                        </div>
                        {/* confirm password */}
                        <div class="form-group">
                          <span className="error-message">
                            <ErrorMessage
                              className="error-message"
                              name="cpassword"
                            />
                          </span>
                          <label
                            htmlFor="signup-confirm-password"
                            class="control-label sr-only"
                          >
                            Confirm Password
                          </label>
                          <Field
                            name="cpassword"
                            type="password"
                            class="form-control"
                            id="signup-confirm-password"
                            placeholder="Confirm Password"
                          />
                        </div>
                        {/* submit */}
                        <Field
                          type="submit"
                          value="Change Password"
                          class="btn btn-primary btn-lg btn-block"
                        />
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ResetPassword;
