import React, { useContext, useEffect, useState } from "react";

import { authContext } from "../Helpers/AuthContext";
import { Formik, Form, Field } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";

const GrowYourBusiness = () => {
  const { authState } = useContext(authContext);

  const [businessData, setBusinessData] = useState({
    title: "",
    heading: "",
    description: "",
    features_heading: "",
    button1Name: "",
    button1Link: "",
    button1Icon: "",
    button2Name: "",
    button2Link: "",
    button2Icon: "",
  });

  useEffect(() => {
    axios
      .get("https://backend.seo-calgary.ca/api/v1/dashboards/getGrowYourBusiness")
      .then((res) => {
        if (res.data.error) {
          toast.error(res.data.error);
        } else {
          setBusinessData(res.data.data);
          setFeatureList(res.data.data.featureList);
        }
      });
  }, []);

  const [featureList, setFeatureList] = useState([]);
  const [serviceName, setServiceName] = useState();
  const [serviceLink, setServiceLink] = useState();
  const [editingIndex, setEditingIndex] = useState(-1);
  const [deletedList] = useState([]);

  const handelAdd = () => {
    if (businessData.title !== "") {
      if (serviceName.trim() === "" && serviceLink.trim() === "") {
        return;
      }
      if (editingIndex >= 0) {
        const newData = [...featureList];
        console.log("newData", newData);
        newData[editingIndex].name = serviceName;
        newData[editingIndex].link = serviceLink;
        newData[editingIndex].isChanged = true;
        setFeatureList(newData);
        setEditingIndex(-1);
      } else if (featureList.length === 0) {
        setFeatureList([{ name: serviceName, link: serviceLink, isNew: true }]);
      } else {
        setFeatureList([
          ...featureList,
          { name: serviceName, link: serviceLink, isNew: true },
        ]);
      }
      setServiceName("");
      setServiceLink("");
    } else {
      if (serviceName.trim() === "" && serviceLink.trim() === "") {
        return;
      }
      if (editingIndex >= 0) {
        const newData = [...featureList];
        newData[editingIndex].name = serviceName;
        newData[editingIndex].link = serviceLink;
        setFeatureList(newData);
        setEditingIndex(-1);
      } else if (featureList.length === 0) {
        setFeatureList([{ name: serviceName, link: serviceLink }]);
      } else {
        setFeatureList([
          ...featureList,
          { name: serviceName, link: serviceLink },
        ]);
      }
      console.log(featureList);
      setServiceName("");
      setServiceLink("");
    }
  };

  const handelDelete = (index) => {
    if (businessData.title !== "") {
      const newData = [...featureList];
      const deletedData = newData.splice(index, 1);
      setFeatureList(newData);
      deletedData[0].id && deletedList.push(deletedData[0]);
    } else {
      const newData = [...featureList];
      newData.splice(index, 1);
      setFeatureList(newData);
    }
  };

  const handelEdit = (index) => {
    if (businessData.title !== "") {
      setServiceName(featureList[index].name);
      setServiceLink(featureList[index].link);
      setEditingIndex(index);
    } else {
      setServiceName(featureList[index].name);
      setServiceLink(featureList[index].link);
      setEditingIndex(index);
    }
  };

  return (
    <div class="row clearfix">
      <div class="col-md-6">
        <div class="card">
          <div class="header">
            <h2>{businessData.title !== "" ? "Update" : "Add"} Content</h2>
          </div>
          <div class="body">
            <Formik
              enableReinitialize
              initialValues={businessData && businessData}
              onSubmit={(values) => {
                console.log("submit", values);
                values.featureList = featureList;
                if (featureList.length > 0) {
                  if (businessData.title !== "") {
                    values.deletedList = deletedList;
                    values.updated_by = authState.name;
                    axios
                      .post(
                        `https://backend.seo-calgary.ca/api/v1/dashboards/updateGrowYourBusiness`,
                        values,
                        {
                          headers: {
                            accessToken: localStorage.getItem("authtoken"),
                          },
                        }
                      )
                      .then((res) => {
                        if (res.data.error) {
                          toast.error(res.data.error);
                        } else {
                          toast.success(res.data.message);
                        }
                      });
                  } else {
                    values.created_By = authState.name;
                    values.WebPackageId = 1;
                    axios
                      .post(
                        "https://backend.seo-calgary.ca/api/v1/dashboards/addGrowYourBusiness",
                        values,
                        {
                          headers: {
                            accessToken: localStorage.getItem("authtoken"),
                          },
                        }
                      )
                      .then((res) => {
                        if (res.data.error) {
                          toast.error(res.data.error);
                        } else {
                          toast.success(res.data.message);
                        }
                      });
                  }
                } else {
                  window.alert("features required");
                }
              }}
            >
              {({ setFieldValue, values }) => {
                return (
                  <>
                    <Form id="basic-form">
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="title">Title :</label>
                        <Field
                          type="text"
                          name="title"
                          id="title"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="heading">Heading: </label>
                        <Field
                          type="text"
                          name="heading"
                          id="heading"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="description">Description: </label>
                        <Field
                          type="text"
                          name="description"
                          id="description"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="features_heading">
                          Features Heading:
                        </label>
                        <Field
                          type="text"
                          name="features_heading"
                          id="features_heading"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="button1Name">Button1 Name:</label>
                        <Field
                          type="text"
                          name="button1Name"
                          id="button1Name"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="button1Link">Button1 Link: </label>
                        <Field
                          type="text"
                          name="button1Link"
                          id="button1Link"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="button1Icon">Button1 Icon: </label>
                        <Field
                          type="text"
                          name="button1Icon"
                          id="button1Icon"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="button2Name">Button2 Name: </label>
                        <Field
                          type="text"
                          name="button2Name"
                          id="button2Name"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="button2Link">Button2 Link: </label>
                        <Field
                          type="text"
                          name="button2Link"
                          id="button2Link"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="button2Icon">Button2 Icon: </label>
                        <Field
                          type="text"
                          name="button2Icon"
                          id="button2Icon"
                          class="form-control"
                          required
                        />
                      </div>

                      <Field
                        type="submit"
                        value={businessData.title !== "" ? "update" : "Add"}
                        class="btn btn-primary"
                      />
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div className="header">
            <h2>Features List:</h2>
          </div>
          <div className="body">
            {/* Feature List */}
            <div class="form-group">
              <label htmlFor="name"> Name </label>
              <input
                type="text"
                name="name"
                value={serviceName}
                onChange={(e) => {
                  setServiceName(e.target.value);
                }}
                id="name"
                class="form-control"
                required
              />
              <label htmlFor="link"> Link </label>
              <input
                type="text"
                name="link"
                value={serviceLink}
                onChange={(e) => {
                  setServiceLink(e.target.value);
                }}
                id="link"
                class="form-control"
                required
              />
              <button onClick={handelAdd}>
                {editingIndex >= 0 ? "Save Edit" : "Save"}
              </button>
            </div>
            {featureList &&
              featureList.map(({ name, link }, index) => (
                <div>
                  <p>Name:{name}</p>
                  <p>Link:{link}</p>
                  <button onClick={() => handelDelete(index)}>Delete</button>
                  <button onClick={() => handelEdit(index)}>Edit</button>
                </div>
              ))}
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default GrowYourBusiness;
