import React, { useContext, useState } from "react";
import { Link, NavLink } from "react-router-dom";

import User from "../../images/user.png";

import { menuContext } from "../../Helpers/MenuContext";
import { authContext } from "../../Helpers/AuthContext";

const Sidebar = () => {
  const { authState } = useContext(authContext);
  const { isMenuOpen, menuData } = useContext(menuContext);

  const [chartCollapse, setChatCollapse] = useState(true);
  const [componentsCollapse, setComponentsCollapse] = useState(true);
  const [pageCollapse, setPageCollapse] = useState(true);

  console.log("áuthstate in sideBar", authState);
  let activeStyle = {
    color: "red",
  };
  return (
    <>
      <div class="left_sidebar" style={isMenuOpen ? { left: 0 } : null}>
        <nav class="sidebar">
          <div class="user-info">
            <div class="detail mt-3">
              <h5 class="mb-0">{authState.name}</h5>
              <small>Admin</small>
            </div>
          </div>
          <ul id="main-menu" class="metismenu">
            <li class="g_heading">Main</li>
            <li>
              <NavLink
                to="/"
                style={({ isActive }) => (isActive ? activeStyle : null)}
              >
                <i class="ti-home"></i>
                <span>Dashboard</span>
              </NavLink>
            </li>
            <li>
              {authState.role === "Super Admin" ? (
                <>
                  <NavLink
                    to="/user-management"
                    style={({ isActive }) => (isActive ? activeStyle : null)}
                  >
                    <i class="ti-vector"></i>
                    <span>User Management</span>
                  </NavLink>
                  <NavLink
                    to="/slider-messages"
                    style={({ isActive }) => (isActive ? activeStyle : null)}
                  >
                    <i class="ti-vector"></i>
                    <span>Slider Messages</span>
                  </NavLink>
                  <NavLink
                    to="/leadsData"
                    style={({ isActive }) => (isActive ? activeStyle : null)}
                  >
                    <i class="ti-vector"></i>
                    <span>Leads Messages</span>
                  </NavLink>
                </>
              ) : null}
            </li>
            <li>
              <NavLink
                to="/site-setting"
                style={({ isActive }) => (isActive ? activeStyle : null)}
              >
                <i class="ti-vector"></i>
                <span>Site Settings</span>
              </NavLink>
            </li>
            <li>
              <Link
                to="#"
                onClick={() => {
                  setChatCollapse(!chartCollapse);
                }}
                class="has-arrow"
              >
                <i class="ti-pie-chart"></i>
                <span>Navigation Bar</span>
              </Link>
              <ul class={chartCollapse ? "collapse" : "collapse in"}>
                <li>
                  <NavLink
                    to="/header-top-links"
                    style={({ isActive }) => (isActive ? activeStyle : null)}
                  >
                    <span>Header Top Links</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/menu-management"
                    style={({ isActive }) => (isActive ? activeStyle : null)}
                  >
                    <span>Menu Management</span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <Link
                to="#"
                onClick={() => {
                  setComponentsCollapse(!componentsCollapse);
                }}
                class="has-arrow"
              >
                <i class="ti-pie-chart"></i>
                <span>Components</span>
              </Link>
              <ul class={componentsCollapse ? "collapse" : "collapse in"}>
                <li>
                  <NavLink
                    to="/main-sliders"
                    style={({ isActive }) => (isActive ? activeStyle : null)}
                  >
                    <span>Main Slider</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/techno-sliders"
                    style={({ isActive }) => (isActive ? activeStyle : null)}
                  >
                    <span>Techno Slider</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/our-services"
                    style={({ isActive }) => (isActive ? activeStyle : null)}
                  >
                    <span>Our Services</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/industry-sliders"
                    style={({ isActive }) => (isActive ? activeStyle : null)}
                  >
                    <span>Industry Slider</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/our-works"
                    style={({ isActive }) => (isActive ? activeStyle : null)}
                  >
                    <span>Our Works</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/our-packages"
                    style={({ isActive }) => (isActive ? activeStyle : null)}
                  >
                    <span>Our Packages</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/web-packages"
                    style={({ isActive }) => (isActive ? activeStyle : null)}
                  >
                    <span>Web Packages</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/why-us"
                    style={({ isActive }) => (isActive ? activeStyle : null)}
                  >
                    <span>Why Us</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/grow-your-business"
                    style={({ isActive }) => (isActive ? activeStyle : null)}
                  >
                    <span>Grow Your Business</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/process"
                    style={({ isActive }) => (isActive ? activeStyle : null)}
                  >
                    <span>Process</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/cta"
                    style={({ isActive }) => (isActive ? activeStyle : null)}
                  >
                    <span>Call To Action</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/testimonials"
                    style={({ isActive }) => (isActive ? activeStyle : null)}
                  >
                    <span>Testimonials</span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink
                to="/footer"
                style={({ isActive }) => (isActive ? activeStyle : null)}
              >
                <i class="ti-vector"></i>
                <span>Footer</span>
              </NavLink>
            </li>
            <li>
              <Link
                to="#"
                onClick={() => {
                  setPageCollapse(!pageCollapse);
                }}
                class="has-arrow"
              >
                <i class="ti-pie-chart"></i>
                <span>Pages</span>
              </Link>
              <ul class={pageCollapse ? "collapse" : "collapse in"}>
                {menuData &&
                  menuData.map((menu) => {
                    if (menu.hasChilds) {
                      return null;
                    } else {
                      return (
                        <>
                          <li key={`_${menu.name}`}>
                            <NavLink
                              to={menu.route}
                              style={({ isActive }) =>
                                isActive ? activeStyle : null
                              }
                            >
                              <span>{menu.name}</span>
                            </NavLink>
                          </li>
                        </>
                      );
                    }
                  })}
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;
