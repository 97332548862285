import React from "react";
import { Route, Routes } from "react-router";
import InitialNavbar from "../Components/Common/InitialNavbar";
import SignIn from "../Pages/SignIn";
import SignUp from "../Pages/SignUp";
import ConfirmEmail from "../Components/UserSystems/ConfirmEmail";
import ForgotPassword from "../Components/UserSystems/ForgotPassword";
import ResetPassword from "../Components/UserSystems/ResetPassword";

const UnAuthLayout = () => {
  return (
    <Routes>
      <Route path="/" exact element={<InitialNavbar />}>
        <Route path="/" exact element={<SignIn />} />
        <Route path="/register" exact element={<SignUp />} />
      </Route>
      <Route path="/confirmEmail/:id" exact element={<ConfirmEmail />} />
      <Route path="/forgot-password" exact element={<ForgotPassword />} />
      <Route
        path="/resetPassword/:id/:token"
        exact
        element={<ResetPassword />}
      />
    </Routes>
  );
};

export default UnAuthLayout;
