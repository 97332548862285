import React, { useContext, useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import axios from "axios";
import "./CkEditor.css";
import { Editor } from "ckeditor5-custom-build/build/ckeditor";

import { Formik, Form, ErrorMessage, Field } from "formik";
import * as yup from "yup";

import { authContext } from "../Helpers/AuthContext";

//Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PreviewImage from "../Helpers/PreviewImage";
import { useLocation } from "react-router";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "image/svg"];

const PageData = ({ data }) => {
  console.log(data);

  const { authState } = useContext(authContext);

  const imageRef = useRef(null);

  const [editorImages] = useState([]);

  const [pageData, setPageData] = useState({
    title: "",
    heading: "",
    sub_heading: "",
    banner_image: "",
    banner_image_alt: "",
    description: "",
  });

  function uploadAdapter(loader) {
    return {
      upload() {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("image", file);
            axios
              .post(
                "https://backend.seo-calgary.ca/api/v1/Pages/image-upload-from-editor",
                body
              )
              .then((res) => {
                console.log(res);
                editorImages.push(res.data.image);
                resolve({ default: `https://backend.seo-calgary.ca/${res.data.image}` });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const { pathname } = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`https://backend.seo-calgary.ca/api/v1/Pages/pageData/${data.id}`)
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
            setPageData({
              title: "",
              heading: "",
              sub_heading: "",
              banner_image: "",
              banner_image_alt: "",
              description: "",
            });
          } else {
            setPageData(res.data);
          }
        });
    };
    fetchData();
  }, [data , pathname]);

  console.log(pageData);

  const validationSchema = yup.object({
    logo: yup
      .mixed()
      .nullable()
      .test(
        "FILE_SIZE",
        "uploaded file too big.",
        (value) => !value || (value && value.size <= 10000000)
      )
      .test(
        "FILE_FORMAT",
        "uploaded file has unsupported format",
        (value) => !value || (value && SUPPORTED_FORMATS.includes(value?.type))
      ),
  });

  return (
    <>
      <div className="row clearfix">
        <Formik
          enableReinitialize
          key={pathname}
          initialValues={
            pageData && pageData.title !== ""
              ? { ...pageData, banner_image: "" }
              : pageData
          }
          validationSchema={validationSchema}
          onSubmit={(values) => {
            const formData = new FormData();

            formData.append("title", values.title);
            formData.append("heading", values.heading);
            formData.append("description", values.description);
            formData.append("sub_heading", values.sub_heading);
            formData.append("banner_image", values.banner_image);
            formData.append("banner_image_alt", values.banner_image_alt);
            formData.append("name", authState.name);
            formData.append("page_image", editorImages);
            axios
              .post(
                `https://backend.seo-calgary.ca/api/v1/Pages/addPageData/${data.id}`,
                formData,
                {
                  headers: {
                    accessToken: localStorage.getItem("authtoken"),
                  },
                }
              )
              .then((res) => {
                if (res.data.error) {
                  toast.error(res.data.error);
                } else {
                  toast.success(res.data.message);
                }
              });
          }}
        >
          {({ setFieldValue, values }) => {
            return (
              <>
                <Form id="basic-form">
                  <div className="card">
                    <div className="header">
                      <h2>
                        {pageData && pageData.title !== "" ? "Edit" : "Add"}{" "}
                        {data.name} Data
                      </h2>
                    </div>
                    <div className="body">
                      <div className="row">
                        <div className="col-md-6">
                          <div class="form-group">
                            {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                            <label htmlFor="title">Page Title :</label>
                            <Field
                              type="text"
                              name="title"
                              id="title"
                              class="form-control"
                              required
                            />
                          </div>
                          <div class="form-group">
                            {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                            <label htmlFor="heading">Heading :</label>
                            <Field
                              type="text"
                              name="heading"
                              id="heading"
                              class="form-control"
                            />
                          </div>
                          <div class="form-group">
                            {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                            <label htmlFor="sub_heading">Sub Heading :</label>
                            <Field
                              type="text"
                              name="sub_heading"
                              id="sub_heading"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="form-group">
                            <label htmlFor="banner_image">
                              Banner Image :{" "}
                              {
                                <span className="error-message">
                                  <ErrorMessage name="banner_image" />
                                </span>
                              }{" "}
                            </label>
                            {values.banner_image ? (
                              <PreviewImage file={values.banner_image} />
                            ) : pageData && pageData.banner_image !== "" ? (
                              <PreviewImage
                                file={`https://backend.seo-calgary.ca/${pageData.banner_image}`}
                              />
                            ) : null}
                            <button
                              className="btn-primary"
                              type="button"
                              onClick={() => {
                                imageRef.current.click();
                              }}
                            >
                              {values.banner_image || pageData.banner_image
                                ? "Edit Image"
                                : "Upload Image"}
                            </button>
                            <input
                              ref={imageRef}
                              type="file"
                              id="banner_image"
                              class="form-control"
                              onChange={(e) => {
                                setFieldValue(
                                  "banner_image",
                                  e.target.files[0]
                                );
                              }}
                              hidden
                            />
                          </div>
                          <div class="form-group">
                            {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                            <label htmlFor="banner_image_alt">
                              Banner Image Alt :
                            </label>
                            <Field
                              type="text"
                              name="banner_image_alt"
                              id="banner_image_alt"
                              class="form-control"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="description">
                              Page Description :
                            </label>
                            <CKEditor
                              id="description"
                              config={{
                                extraPlugins: [uploadPlugin],
                              }}
                              editor={Editor}
                              data={
                                pageData
                                  ? pageData.description
                                  : "<h1>hello</h1>"
                              }
                              onReady={(editor) => {
                                // You can store the "editor" and use when it is needed.
                                console.log("Editor is ready to use!", editor);
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                console.log("data", data);
                                console.log({ event, editor, data });
                                setFieldValue("description", data);
                              }}
                              onBlur={(event, editor) => {
                                console.log("Blur.", editor);
                              }}
                              onFocus={(event, editor) => {
                                console.log("Focus.", editor);
                              }}
                            />
                          </div>
                          <Field
                            type="submit"
                            value="Submit"
                            class="btn btn-primary"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default PageData;
