import React from 'react'
import Logo from '../../images/brand/icon_black.svg'

import { useNavigate } from "react-router";

import { Formik, Field, ErrorMessage, Form } from "formik";
import * as yup from "yup";

import axios from "axios";

const ForgotPassword = () => {

    const navigate = useNavigate();

  const initialValues = {
    email: "",
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required("Email is Required")
      .email("Please enter a valid email"),
  });

  const handelSubmit = (values) => {
    console.log(values);
    axios
      .post("https://backend.seo-calgary.ca/api/v1/users/userForgotPassword", values)
      .then((res) => {
        if (res.data.error) {
          window.alert(res.data.error);
          console.log(res.data.error);
        } else {
          window.alert(res.data.message);          
          navigate("/");

        }
      });
  };

  return (
    <div id="wrapper">
    <div class="vertical-align-wrap">
      <div class="vertical-align-middle auth-main">
        <div class="auth-box">
          <div class="top">
            <img src={Logo} alt="Lucid" />
            <strong>Big</strong> <span>Bucket</span>
          </div>
          <div class="card">
            <div class="header">
              <p class="lead">Enter Your Email Address</p>
            </div>
            <div class="body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handelSubmit}
              >
                <Form className="form-auth-small">
                  {/* email */}
                  <div class="form-group">
                    <span className="error-message">
                      <ErrorMessage
                        className="error-message"
                        name="email"
                      />
                    </span>
                    <label
                      htmlFor="signup-email"
                      class="control-label sr-only"
                    >
                      Email
                    </label>
                    <Field
                      name="email"
                      type="email"
                      class="form-control"
                      id="signup-email"
                      placeholder="Your email"
                    />
                  </div>
                  {/* submit */}
                  <Field
                    type="submit"
                    value="Send Email"
                    class="btn btn-primary btn-lg btn-block"
                  />
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ForgotPassword