import React, { useContext, useEffect, useState } from "react";

import { authContext } from "../Helpers/AuthContext";
import { Formik, Form, Field } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";

const Footer = () => {
  const { authState } = useContext(authContext);

  const [footerData, setFooterData] = useState({
    facebook_url: "",
    twitter_url: "",
    linkendIn_url: "",
    youtube_url: "",
    instagram_url: "",
    addressLine1: "",
    addressLine2: "",
    email1: "",
    email2: "",
    phone1: "",
    phone2: "",
    copyright_text: "",
  });

  useEffect(() => {
    axios
      .get("https://backend.seo-calgary.ca/api/v1/dashboards/getFooter")
      .then((res) => {
        if (res.data.error) {
          toast.error(res.data.error);
        } else {
          setFooterData(res.data.data);
        }
      });
  }, []);

  return (
    <div class="row clearfix">
      <div class="col-md-6">
        <div class="card">
          <div class="header">
            <h2>{footerData.addressLine1 !== "" ? "Update" : "Add"} Content</h2>
          </div>
          <div class="body">
            <Formik
              enableReinitialize
              initialValues={footerData && footerData}
              onSubmit={(values) => {
                console.log("submit", values);

                if (footerData.addressLine1 !== "") {
                  values.updated_by = authState.name;
                  axios
                    .post(
                      `https://backend.seo-calgary.ca/api/v1/dashboards/updateFooter`,
                      values,
                      {
                        headers: {
                          accessToken: localStorage.getItem("authtoken"),
                        },
                      }
                    )
                    .then((res) => {
                      if (res.data.error) {
                        toast.error(res.data.error);
                      } else {
                        toast.success(res.data.message);
                      }
                    });
                } else {
                  values.created_By = authState.name;
                  axios
                    .post(
                      "https://backend.seo-calgary.ca/api/v1/dashboards/addFooter",
                      values,
                      {
                        headers: {
                          accessToken: localStorage.getItem("authtoken"),
                        },
                      }
                    )
                    .then((res) => {
                      if (res.data.error) {
                        toast.error(res.data.error);
                      } else {
                        toast.success(res.data.message);
                      }
                    });
                }
              }}
            >
              {({ setFieldValue, values }) => {
                return (
                  <>
                    <Form id="basic-form">
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="facebook_url">facebook_url: </label>
                        <Field
                          type="text"
                          name="facebook_url"
                          id="facebook_url"
                          class="form-control"
                          required
                        />
                      </div>

                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="twitter_url">twitter_url:</label>
                        <Field
                          type="text"
                          name="twitter_url"
                          id="twitter_url"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="linkendIn_url">linkendIn_url: </label>
                        <Field
                          type="text"
                          name="linkendIn_url"
                          id="linkendIn_url"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="youtube_url">youtube_url: </label>
                        <Field
                          type="text"
                          name="youtube_url"
                          id="youtube_url"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="instagram_url">instagram_url: </label>
                        <Field
                          type="text"
                          name="instagram_url"
                          id="instagram_url"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="addressLine1">addressLine1: </label>
                        <Field
                          type="text"
                          name="addressLine1"
                          id="addressLine1"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="addressLine2">addressLine2: </label>
                        <Field
                          type="text"
                          name="addressLine2"
                          id="addressLine2"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="email1">email1: </label>
                        <Field
                          type="text"
                          name="email1"
                          id="email1"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="email2">email2: </label>
                        <Field
                          type="text"
                          name="email2"
                          id="email2"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="phone1">phone1: </label>
                        <Field
                          type="text"
                          name="phone1"
                          id="phone1"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="phone2">phone2: </label>
                        <Field
                          type="text"
                          name="phone2"
                          id="phone2"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="copyright_text">copyright_text: </label>
                        <Field
                          type="text"
                          name="copyright_text"
                          id="copyright_text"
                          class="form-control"
                          required
                        />
                      </div>                    

                      <Field
                        type="submit"
                        value={footerData.addressLine1 !== "" ? "update" : "Add"}
                        class="btn btn-primary"
                      />
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Footer;
