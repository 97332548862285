import React, { useContext, useEffect, useRef, useState } from "react";

import { authContext } from "../../Helpers/AuthContext";
import { Formik, Form, Field } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import PreviewImage from "../../Helpers/PreviewImage";

import axios from "axios";
import { useNavigate, useParams } from "react-router";

const AddTabListContent = () => {
  const { authState } = useContext(authContext);
  const navigate = useNavigate();

  const { tabId, contentId } = useParams();
  const imageRef = useRef(null);

  const [tabContentData, setTabContentData] = useState({
    image: "",
    alt: "",
    link: "",
    title: "",
  });
  console.log(tabContentData);

  useEffect(() => {
    if (contentId) {
      axios
        .get(
          `https://backend.seo-calgary.ca/api/v1/dashboards/getWorkTabListContentDataByContentId/${contentId}`
        )
        .then((res) => {
          if (res.data.error) {
            toast.error(res.data.error);
          } else {
            setTabContentData(res.data.data);
          }
        });
    }
  }, [contentId]);

  return (
    <div class="row clearfix">
      <div class="col-md-6">
        <div class="card">
          <div class="header">
            <h2>{tabContentData.alt !== "" ? "Update" : "Add"} Content</h2>
          </div>
          <div class="body">
            <Formik
              enableReinitialize
              initialValues={
                tabContentData.alt !== ""
                  ? { ...tabContentData, image: "" }
                  : tabContentData
              }
              onSubmit={(values) => {
                console.log("submit", values);

                const formData = new FormData();

                formData.append("alt", values.alt);
                formData.append("link", values.link);
                formData.append("title", values.title);

                if (tabContentData.alt !== "") {
                  if (values.image !== "") {
                    formData.append("image", values.image);
                    formData.append("updated_by", values.updated_by);
                    axios
                      .post(
                        `https://backend.seo-calgary.ca/api/v1/dashboards/updateWorkTabListContentById/${contentId}`,
                        formData,
                        {
                          headers: {
                            accessToken: localStorage.getItem("authtoken"),
                          },
                        }
                      )
                      .then((res) => {
                        if (res.data.error) {
                          toast.error(res.data.error);
                        } else {
                          toast.success(res.data.message);
                          navigate(`/view-edit-add-tab-list-content/${tabId}`);
                        }
                      });
                  } else {
                    values.updated_by = authState.name;
                    axios
                      .post(
                        `https://backend.seo-calgary.ca/api/v1/dashboards/updateWorkTabListContentByIdWithoutPicture/${contentId}`,
                        values,
                        {
                          headers: {
                            accessToken: localStorage.getItem("authtoken"),
                          },
                        }
                      )
                      .then((res) => {
                        if (res.data.error) {
                          toast.error(res.data.error);
                        } else {
                          toast.success(res.data.message);
                          navigate(`/view-edit-add-tab-list-content/${tabId}`);
                        }
                      });
                  }
                } else {
                  formData.append("image", values.image);
                  formData.append("created_by", values.created_by);
                  formData.append("OurWorkTabListId", tabId);
                  axios
                    .post(
                      "https://backend.seo-calgary.ca/api/v1/dashboards/addWorkTabListContent",
                      formData,
                      {
                        headers: {
                          accessToken: localStorage.getItem("authtoken"),
                        },
                      }
                    )
                    .then((res) => {
                      if (res.data.error) {
                        toast.error(res.data.error);
                      } else {
                        toast.success(res.data.message);
                        navigate(`/view-edit-add-tab-list-content/${tabId}`);
                      }
                    });
                }
              }}
            >
              {({ setFieldValue, values }) => {
                return (
                  <>
                    <Form id="basic-form">
                      <div class="form-group">
                        <label htmlFor="image">Image :</label>
                        {values.image && values.image ? (
                          <PreviewImage file={values.image} />
                        ) : tabContentData && tabContentData.image !== "" ? (
                          <PreviewImage
                            file={`https://backend.seo-calgary.ca/${tabContentData.image}`}
                          />
                        ) : null}
                        <button
                          className="btn-primary"
                          type="button"
                          onClick={() => {
                            imageRef.current.click();
                          }}
                        >
                          {values.image ||
                          (tabContentData && tabContentData.image)
                            ? "Edit Image"
                            : "Upload Image"}
                        </button>
                        <input
                          ref={imageRef}
                          type="file"
                          id="image"
                          class="form-control"
                          onChange={(e) => {
                            setFieldValue("image", e.target.files[0]);
                          }}
                          hidden
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="alt">Alt :</label>
                        <Field
                          type="text"
                          name="alt"
                          id="alt"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="link">Link: </label>
                        <Field
                          type="text"
                          name="link"
                          id="link"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                        <label htmlFor="title">Title: </label>
                        <Field
                          type="text"
                          name="title"
                          id="title"
                          class="form-control"
                          required
                        />
                      </div>
                      <Field
                        type="submit"
                        value={tabContentData.alt !== "" ? "update" : "Add"}
                        class="btn btn-primary"
                      />
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default AddTabListContent;
