import React, { useContext, useEffect, useState } from "react";

import Menu from "../Components/Menu/Menu";

//Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavLinksForm from "../Components/Forms/NavLinksForm";
import axios from "axios";
import { authContext } from "../Helpers/AuthContext";
import { menuManagementContext } from "../Helpers/MenuManagement";

const MenuManagement = () => {
  const { authState } = useContext(authContext);

  const [dataChange, setDataChange] = useState(false);

  const [menuItems, setMenuItems] = useState(null);

  const fetchData = async () => {
    console.log("fetchingData");
    await axios
      .get("https://backend.seo-calgary.ca/api/v1/dashboards/getParentNavLinks")
      .then((res) => {
        if (res.data.error) {
          window.alert(res.data.error);
        } else {
          console.log(res.data);
          setMenuItems(res.data.data);
          console.log(res.data.message);
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, [dataChange]);

  const makeRequest = (values) => {
    values.created_By = authState && authState.name;
    console.log(values);
    axios
      .post("https://backend.seo-calgary.ca/api/v1/dashboards/addNavLinks", values, {
        headers: {
          accessToken: localStorage.getItem("authtoken"),
        },
      })
      .then((res) => {
        if (res.data.error) {
          toast.error(res.data.error);
        } else {
          setDataChange(!dataChange);
          toast.success(res.data.message);
        }
      });
  };

  const handlePublish = (id) => {
    axios
      .get(`https://backend.seo-calgary.ca/api/v1/dashboards/publishMenu/${id}`, {
        headers: {
          accessToken: localStorage.getItem("authtoken"),
        },
      })
      .then((res) => {
        if (res.data.error) {
          toast.error(res.data.error);
        } else {
          setDataChange(!dataChange);
          toast.success(res.data.message);
        }
      });
  };

  const handleUnpublish = (id) => {
    axios
      .get(`https://backend.seo-calgary.ca/api/v1/dashboards/unpublishMenu/${id}`, {
        headers: {
          accessToken: localStorage.getItem("authtoken"),
        },
      })
      .then((res) => {
        if (res.data.error) {
          toast.error(res.data.error);
        } else {
          setDataChange(!dataChange);
          toast.success(res.data.message);
        }
      });
  };

  const handleEdit = (element) => {
    element.updatedBy = authState.name;
    axios
      .post(
        `https://backend.seo-calgary.ca/api/v1/dashboards/editMenu/${element.id}`,
        element,
        {
          headers: {
            accessToken: localStorage.getItem("authtoken"),
          },
        }
      )
      .then((res) => {
        if (res.data.error) {
          toast.error(res.data.error);
        } else {
          setDataChange(!dataChange);
          toast.success(res.data.message);
        }
      });
  };

  const handleDelete = (id) => {
    if (window.confirm("Delete the item?")) {
      axios
        .get(`https://backend.seo-calgary.ca/api/v1/dashboards/deleteMenu/${id}`, {
          headers: {
            accessToken: localStorage.getItem("authtoken"),
          },
        })
        .then((res) => {
          if (res.data.error) {
            toast.error(res.data.error);
          } else {
            setDataChange(!dataChange);
            toast.success(res.data.message);
          }
        });
    }
  };

  return (
    <>
      {menuItems && (
        <>
          <menuManagementContext.Provider value={{ menuItems, setMenuItems }}>
            <div class="row clearfix">
              <div class="col-lg-12">
                <div class="card">
                  <div class="header">
                    <h2>Navigation Menu</h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="row clearfix">
              <div class="col-md-6">
                <div class="card">
                  <div class="header">
                    <h2>Add Menu</h2>
                  </div>
                  <div class="body">
                    <NavLinksForm addLinks={makeRequest} />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card">
                  <div class="header">
                    <h2>
                      <strong>Manage Menu</strong>( Drag The elements to reorder
                      the position of Navigation Menu )
                    </h2>
                  </div>
                  <div class="body">
                    <Menu
                      handlePublish={handlePublish}
                      handleUnpublish={handleUnpublish}
                      handleEdit={handleEdit}
                      handleDelete={handleDelete}
                    />
                  </div>
                </div>
              </div>
            </div>
          </menuManagementContext.Provider>
        </>
      )}

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default MenuManagement;
