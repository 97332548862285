import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import axios from "axios";

import { Formik, Form, Field } from "formik";

const ActionTable = ({ data, deleteLink, editData }) => {
  const [values, setValues] = useState({});
  const [show, setShow] = useState(false);

  const [editableData, setEditableData] = useState();

  const handleClose = () => setShow(false);
  const handleShow = (element) => {
    setEditableData(element);
    setShow(true);
  };

  const handelSubmit = () => {
    editData(values);
    handleClose();
  };
  

  console.log(data);
  return (
    <>
      {data && data.length > 0 ? (
        <div class="body">
          <div class="table-responsive">
            <table class="table table-hover mb-0 c_list">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Route</th>
                  <th>Icon</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((element, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>
                            <span>{element.name}</span>
                          </td>
                          <td>
                            <span>{element.route}</span>
                          </td>
                          <td>
                            <span>{element.icon}</span>
                          </td>
                          <td>
                            <button
                              type="button"
                              class="btn btn-info btn-sm"
                              onClick={() => handleShow(element)}
                              title="Edit"
                            >
                              <i class="fa fa-edit"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-danger btn-sm"
                              onClick={() => deleteLink(element.id)}
                              title="Delete"
                            >
                              <i class="fa fa-trash-o"></i>
                            </button>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <h4>No data Found</h4>
      )}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div class="row clearfix">
            <div class="col-md-6">
              <div class="card">
                <div class="header">
                  <h2>Add Link</h2>
                </div>
                <div class="body">
                  <Formik
                    innerRef={(formikActions) => (formikActions? setValues(formikActions.values) : setValues({}))}
                    initialValues={editableData && editableData}
                    onSubmit={(values) => {
                      editData(values);
                    }}
                  >
                        <Form id="basic-form">
                          <div class="form-group">
                            {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                            <label htmlFor="name">Route Name :</label>
                            <Field
                              type="text"
                              name="name"
                              id="name"
                              class="form-control"
                              required
                            />
                          </div>
                          <div class="form-group">
                            {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                            <label htmlFor="route">Route Link :</label>
                            <Field
                              type="text"
                              name="route"
                              id="route"
                              class="form-control"
                              required
                            />
                          </div>
                          <div class="form-group">
                            {/* <span className="error-message"><ErrorMessage name='comapny_name' /></span> */}
                            <label htmlFor="icon">
                              Icon class(font-awesome) :
                            </label>
                            <Field
                              type="text"
                              name="icon"
                              id="icon"
                              class="form-control"
                              required
                            />
                          </div>
                        </Form>
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handelSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ActionTable;
